<template>
  <a-modal v-model:visible="visible" :title="modalTitle" :width="484" @ok="handleOk" :after-close="afterClose">
    <div class="mult_operation_wrapper w100 h100 flow_auto">
      <a-form class="mult_operation" :label-col="{ span: 5 }" :wrapper-col="{ span: 19 }" :colon="false" label-align="left">
        <section>
          <a-row type="flex" align="middle">
            <a-col :span="24">
              <a-form-item label="日期范围" >
                <a-range-picker :placeholder="['开始日期', '结束日期']" v-model:value="defaultRange" separator="到" :format="[dateRangeFormat, dateRangeFormat]" :ranges="dateRanges" :disabled-date="disabledDateRange" @change="onDateRangeChange" :disabled="tableLoading" />
              </a-form-item>
            </a-col>
          </a-row>
        </section>
      </a-form>
    </div>
  </a-modal>
</template>

<script>
import moment from 'moment';
import { ref} from "vue";
import { pullSyncAdvLog } from '@/api/mediaAccount/mediaAccount';
export default {
  name: 'SyncAdvLog',
  emits: ['afterSave'],
  data() {
    const dateRangeFormat = 'YYYY-MM-DD';
    const dateRanges = {
      昨天: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
      最近三天: [moment().subtract(2, 'days'), moment()],
      本周: [moment().startOf('week'), moment.min(moment(), moment().endOf('week'))],
      最近七天: [moment().subtract(6, 'days'), moment()],
      本月: [moment().startOf('month'), moment.min(moment(), moment().endOf('month'))],
      最近三十天: [moment().subtract(29, 'days'), moment()],
      最近九十天: [moment().subtract(89, 'days'), moment()],
    };
    //禁止rangepick选择今天开始的日期
    const disabledDateRange = current => {
      return current && current > moment().add(1, 'days').startOf('day');
      // return moment().add(1, 'days').startOf('day');
    };
    // rangepicker默认选中日期
    const defaultRange = ref([moment(), moment()]);
    const mediaChannel = 'TT' ;
    const accountList = [];
    const userId = this.$store.state.oauth.userInfo.userId;
    const empty = { startDate: moment().format(dateRangeFormat), endDate: moment().format(dateRangeFormat),pull_ad:'N',page:1,pageSize:1000};
    return {
      visible: false,
      radioValue: 1,
      componentName: '',
      empty,
      formData: { ...empty },
      mode: '',
      mediaChannel,
      userId,
      tableLoading: false,
      dateRanges,
      defaultRange,
      dateRangeFormat,
      disabledDateRange,
      accountList,
    };
  },
  computed: {
    modalTitle() {
      return { syncAdvLog: '同步操作日志' }[this.mode];
    },
  },
  watch: {
    visible(val) {
      this.formData.pull_ad = 'N';
    },
  },
  methods: {
    // 开始时间结束时间选择
    onDateRangeChange(val, dateString) {
      this.formData.startDate = dateString[0];
      this.formData.endDate = dateString[1];
    },
    // 点击保存
    async handleOk() {
      this.tableLoading = true;
      let obj = { extras: this.formData, accountList: this.accountList,userId:this.userId};
      let res ;
      if(this.mediaChannel === 'TT'){
        res = await pullSyncAdvLog(this.mediaChannel,obj);
      }else {
        this.$emit('afterSave');
        this.$message.success('此渠道暂未开通该功能！');
        this.visible = false;
        return ;
      }
      if (res.code === 0) {
        this.$emit('afterSave');
        this.$message.success('稍等片刻,操作日志同步已提交媒体！');
        this.visible = false;
      }else{
        this.$message.error(res.msg);
        this.visible = false;
      }
      this.tableLoading = false;
      return ;
    },
    // 关闭批量绑定弹框
    afterClose() {
      this.formData = { ...this.empty };
      this.dateRange = [];
    },
  },
};
</script>

<style  lang="less">
.mult_operation {
  overflow: auto;

  .ant-form-item-label {
    line-height: 40px;
  }
  .anticon-question-circle svg {
    font-size: 14px;
  }
  .ant-calendar-picker-input {
    height: 32px !important;
  }
  .ant-radio-button-wrapper {
    width: 96px;
    text-align: center;
  }
}

</style>
