<template>
  <div class="media w100 h100">
    <div class="filter_row">
      <a-tabs v-model:activeKey="activeTabKey" @change="onTabChange">
        <a-tab-pane key="TT" tab="巨量引擎" force-render></a-tab-pane>
        <a-tab-pane key="GDT" tab="腾讯广告"></a-tab-pane>
        <a-tab-pane key="KS" tab="磁力引擎"></a-tab-pane>
        <a-tab-pane key="QC" tab="巨量千川"></a-tab-pane>
        <a-tab-pane key="AQY" tab="爱奇艺"></a-tab-pane>
<!--
        <a-tab-pane v-if="true" v-hasPermission="'ACCOUNT_MATERIAL_CL'" key="CL" tab="账号素材清理"></a-tab-pane>
-->
      </a-tabs>
      <a-form :model="filterConditions">
      <a-row type="flex">
        <div style="flex: 1">

          <a-row v-show="activeTabKey !== 'CL'"  class="media-choice">
            <a-col :span="4">
              <a-form-item style="min-width: 5em" :wrapper-col="{ span: 24 }">
                <a-input v-model:value="filterConditions.mdaIds" placeholder="批量查询ID请用空格隔开" @press-enter="enterIdSearch"></a-input>
              </a-form-item>
            </a-col>
            <a-col :span="4">
              <a-form-item style="min-width: 5em" :wrapper-col="{ span: 24 }">
                <a-input v-model:value="filterConditions.mdaNames" placeholder="批量查询账号名称请用空格隔开" @press-enter="enterIdSearch"></a-input>
              </a-form-item>
            </a-col>
            <a-col :span="4" v-hasPermission="'MEDIA_FILTER_INDUSTRY'">
              <a-form-item :wrapper-col="{ span: 24 }">
                <a-select v-model:value="filterConditions.industryModelBindState" :disabled="!!filterConditions.industryModel" placeholder="请选择业务模式绑定状态" allow-clear style="width: 100%">
                  <a-select-option key="BINDED" value="BINDED">已绑定</a-select-option>
                  <a-select-option key="NOBIND" value="NOBIND">未绑定</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="4" v-hasPermission="'MEDIA_FILTER_INDUSTRY'">
              <a-form-item :wrapper-col="{ span: 24 }">
                <a-select v-model:value="filterConditions.industryModel" :disabled="filterConditions.industryModelBindState == 'NOBIND'" placeholder="请选择业务模式" allow-clear style="width: 100%">
                  <a-select-option v-hasPermission="'MEDIA_FILTER_INDUSTRY_SELF'" key="Self" value="Self">二代</a-select-option>
                  <a-select-option key="Substitute" value="Substitute">代运营</a-select-option>
                  <a-select-option key="Service" value="Service">服务商</a-select-option>
                  <a-select-option key="CusSelfOpera" value="CusSelfOpera">客户自运营</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="4">
              <a-form-item :wrapper-col="{ span: 24 }">
                <a-select v-model:value="filterConditions.companyBindState" :disabled="!!filterConditions.companyCode" placeholder="请选择客户绑定状态" show-search :filter-option="true" option-filter-prop="label" allow-clear style="width: 100%" :dropdown-match-select-width="false" @change="onCompanyBindStateChange">
                  <a-select-option value="BINDED">已绑定</a-select-option>
                  <a-select-option value="NOBIND">未绑定</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="4">
              <a-form-item :wrapper-col="{ span: 24 }">
                <a-select v-model:value="filterConditions.companyCode" :disabled="filterConditions.companyBindState == 'NOBIND'" placeholder="请输入客户名称" show-search :filter-option="true" option-filter-prop="label" allow-clear style="width: 100%" :dropdown-match-select-width="false" @change="onCompanyNameChange">
                  <template #notFoundContent>
                    <a-empty :image="simpleImage">
                      <template #description>
                        <span>暂无数据</span>
                      </template>
                    </a-empty>
                  </template>
                  <a-select-option :value="item.value" v-for="item in companyNameOptions" :label="item.text" :key="item.value">{{ item.text }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="4" v-if="filterConditionShow.PRODUCT_SHOW">
              <a-form-item :wrapper-col="{ span: 24 }">
                <a-select v-model:value="filterConditions.projectDistributionState" placeholder="请选择产品分配状态" :disabled="!!filterConditions.projectCode" @change="value => changeStatus(value, 'projectCode')" allow-clear style="width: 100%">
                  <a-select-option value="DISTRIBUTION">分配</a-select-option>
                  <a-select-option value="NODISTRIBUTION">未分配</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="4" v-if="filterConditionShow.PRODUCT_SHOW">
              <a-form-item :wrapper-col="{ span: 24 }">
                <a-select show-search :filter-option="true" option-filter-prop="label" v-model:value="filterConditions.projectCode" :disabled="filterConditions.projectDistributionState === 'NODISTRIBUTION' || filterConditions.industryModel == 'Self'" placeholder="请选择产品" @change="onProjectChange" allow-clear style="width: 100%" :dropdown-match-select-width="false">
                  <template #notFoundContent>
                    <a-empty :image="simpleImage">
                      <template #description>
                        <span>暂无数据</span>
                      </template>
                    </a-empty>
                  </template>
                  <a-select-option :value="item.projectCode" v-for="item in getChannelProjectList" :label="item.projectName" :key="item.id">{{ item.projectName }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="4" v-if="filterConditionShow.PERSON_SHOW && (!filterConditions.industryModel || filterConditions.industryModel == 'Substitute')">
              <a-form-item :wrapper-col="{ span: 24 }">
                <a-select v-model:value="filterConditions.operatorDistributionState" placeholder="请选择运营分配状态" :disabled="!!filterConditions.operatorId" @change="value => changeStatus(value, 'operatorId')" allow-clear style="width: 100%">
                  <a-select-option value="DISTRIBUTION">分配</a-select-option>
                  <a-select-option value="NODISTRIBUTION">未分配</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="4" v-if="filterConditionShow.PERSON_SHOW && (!filterConditions.industryModel || filterConditions.industryModel == 'Substitute')">
              <a-form-item :wrapper-col="{ span: 24 }">
                <a-select v-model:value="filterConditions.operatorId" placeholder="请选择所属运营" show-search :disabled="filterConditions.operatorDistributionState === 'NODISTRIBUTION'" :filter-option="true" option-filter-prop="label" @change="value => onPersonChange(value, 'operatorId')" allow-clear style="width: 100%">
                  <template #notFoundContent>
                    <a-empty :image="simpleImage">
                      <template #description>
                        <span>暂无数据</span>
                      </template>
                    </a-empty>
                  </template>
                  <a-select-option :value="item.userId" v-for="item in distributionPerson" :label="item.userCode" :key="item.userId">{{ item.userCode }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="4" v-if="filterConditionShow.PERSON_SHOW && (!filterConditions.industryModel || filterConditions.industryModel == 'Service')">
              <a-form-item :wrapper-col="{ span: 24 }">
                <a-select v-model:value="filterConditions.facilitatorDistributionState" placeholder="请选择服务商分配状态" :disabled="!!filterConditions.facilitatorId" @change="value => changeStatus(value, 'facilitatorId')" allow-clear style="width: 100%">
                  <a-select-option value="DISTRIBUTION">分配</a-select-option>
                  <a-select-option value="NODISTRIBUTION">未分配</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="4" v-if="filterConditionShow.PERSON_SHOW && (!filterConditions.industryModel || filterConditions.industryModel == 'Service')">
              <a-form-item :wrapper-col="{ span: 24 }">
                <a-select v-model:value="filterConditions.facilitatorId" placeholder="请选择所属服务商" show-search :disabled="filterConditions.facilitatorDistributionState === 'NODISTRIBUTION'" :filter-option="true" option-filter-prop="label" @change="value => onPersonChange(value, 'facilitatorId')" allow-clear style="width: 100%">
                  <template #notFoundContent>
                    <a-empty :image="simpleImage">
                      <template #description>
                        <span>暂无数据</span>
                      </template>
                    </a-empty>
                  </template>
                  <a-select-option :value="item.facilitatorId" v-for="item in facilitatorList" :label="item.facilitatorName" :key="item.facilitatorId">{{ item.facilitatorName }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="4">
              <a-form-item :wrapper-col="{ span: 24 }">
                <a-select v-model:value="filterConditions.invoiceName" placeholder="请选择开户主体" v-bind="$filterOptions" allow-clear style="width: 100%">
                  <template #notFoundContent>
                    <a-empty :image="simpleImage">
                      <template #description>
                        <span>暂无数据</span>
                      </template>
                    </a-empty>
                  </template>
                  <a-select-option :value="item.invoiceName" v-for="item in invoiceNameOptions" :label="item.invoiceName" :key="item.id">{{ item.invoiceName }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="4">
              <a-form-item :wrapper-col="{ span: 24 }">
                <a-select v-model:value="filterConditions.priorityType" placeholder="请选择消耗情况" allow-clear style="width: 100%" @change="onPriorityTypeChange">
                  <template #notFoundContent>
                    <a-empty :image="simpleImage">
                      <template #description>
                        <span>暂无数据</span>
                      </template>
                    </a-empty>
                  </template>
                  <a-select-option value="NO">无消耗</a-select-option>
                  <a-select-option value="SEVENDAY">七日内有消耗</a-select-option>
                  <a-select-option value="NEW">当日新增</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="4">
              <a-form-item :wrapper-col="{ span: 24 }">
                <a-select v-model:value="filterConditions.activeStatus" placeholder="请选择账号活跃状态" allow-clear style="width: 100%" @change="onActiveAccountChange">
                  <a-select-option value="1">活跃</a-select-option>
                  <a-select-option value="0">非活跃</a-select-option>
                  <a-select-option value="all">所有</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="4">
              <a-form-item :wrapper-col="{ span: 24 }">
                <a-select v-model:value="filterConditions.businessDept" placeholder="请选择绑定业务部门" allow-clear style="width: 100%" @change="onSearchBtnClick">
                  <a-select-option v-for="dept in businessDeptList" :key="dept.name" :value="dept.value" :label="dept.name">{{ dept.name }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col v-if="activeTabKey == 'TT' || activeTabKey == 'AQY' " :span="4">
              <a-form-item :wrapper-col="{ span: 12 }" label="今日最低消耗:">
                <a-input-number style="width: 163px"  :min="0" v-model:value="filterConditions.todayMinCost"  @keydown.enter="onSearchBtnClick" > </a-input-number>
              </a-form-item>
            </a-col>
            <a-col v-if="activeTabKey == 'TT' || activeTabKey == 'AQY' "  :span="4">
              <a-form-item  :wrapper-col="{ span: 12 }" label="昨日最低消耗:">
                <a-input-number style="width: 163px"  :min="0" v-model:value="filterConditions.yesterdayMinCost" @keydown.enter="onSearchBtnClick" > </a-input-number>
              </a-form-item>
            </a-col>
          </a-row>

<!--          素材清理-->
          <a-row v-show="activeTabKey === 'CL'"  class="media-choice">

            <a-col v-if="true" :span="4" >
              <a-form-item label="查询纬度">
                <a-radio-group v-model:value="filterConditions.selectType" button-style="solid" @change="onSearchBtnClick" >
                  <a-radio-button value="account" defaultValue >账户纬度</a-radio-button>
                  <a-radio-button value="customer">客户纬度</a-radio-button>
                </a-radio-group>
              </a-form-item>
            </a-col>

            <a-col :span="7" >
              <a-form-item :rules="[{ required: true, message: '请选择' }]" :wrapper-col="{ span: 24 }" required  >
                <a-select v-model:value="filterConditions.agentId"  placeholder="请选择代理商主帐户-必填" v-bind="$filterOptions" style="width: 100%">
                  <a-select-option :value="accManager.advertiserId" :label="accManager.advertiserId+'-'+accManager.advertiserName " v-for="accManager in managerAccountListTemp" :key="accManager.advertiserId">{{ accManager.advertiserId }}-{{accManager.advertiserName}}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :span="4"  >
              <a-form-item :rules="[{ required: true, message: '请选择' }]" :wrapper-col="{ span: 24 }" required  >
                <a-month-picker   required="true"  v-model:value="filterConditions.period"  :format="'YYYY-MM'" placeholder="请选择月份-必填"/>
              </a-form-item>
            </a-col>

            <a-col :span="2" >
              <a-form-item :wrapper-col="{ span: 24 }"  >
                <a-select  v-model:value="filterConditions.materialIsEffective"  placeholder="投放状态" allow-clear style="width: 100%">
                  <a-select-option value="Y" >在投</a-select-option>
                  <a-select-option value="N" >未投</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :span="5" >
              <a-form-item :wrapper-col="{ span: 24 }"  >
                <a-select mode="multiple"  v-model:value="filterConditions.clearMaterialTypes"  placeholder="素材标签" allow-clear style="width: 100%">
                  <a-select-option  :value="item" :label="item" v-for="item in clearMaterialTypes" :key="item">{{ item }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col v-if="filterConditions.selectType == null || filterConditions.selectType == 'account' " :span="4">
              <a-form-item style="min-width: 1em" :wrapper-col="{ span: 24 }">
                <a-input v-model:value="filterConditions.advertiserId" placeholder="账户ID" @press-enter="enterIdSearch"></a-input>
              </a-form-item>
            </a-col>

            <a-col :span="4">
              <a-form-item style="min-width: 5em" :wrapper-col="{ span: 24 }">
                <a-input v-model:value="filterConditions.customerName" placeholder="客户名称" @press-enter="enterIdSearch"></a-input>
              </a-form-item>
            </a-col>

          </a-row>
        </div>
        <a-space>
          <a-button type="primary" @click="onSearchBtnClick" :disabled="tableLoading">筛选</a-button>
          <a-button @click="resetFilter" :disabled="tableLoading">重置</a-button>
        </a-space>
      </a-row>
      </a-form>
    </div>
    <div  v-show="activeTabKey !== 'CL'"  class="table_wrapper">
      <a-space :size="10" class="table_operation">
        <a-button v-hasPermission="'MEDIA_BINDINDUSTRY'" :disabled="batchBindIndustryDisabled" @click="onBatchOperationClick('industrymode')">批量业务模式</a-button>
        <a-button :disabled="batchBindProjectDisabled" @click="onBatchOperationClick('product')">批量绑定产品</a-button>
        <a-button :disabled="batchBindPersonDisabled" @click="onBatchOperationClick('operation')">批量绑定运营</a-button>
        <a-button v-hasPermission="'MEDIA_CLEAR_MATERIAL'" :disabled="batchBindIndustryDisabled" @click="onBatchOperationClick('clearMaterial')">批量清理素材</a-button>
        <a-button :disabled="batchBindIndustryDisabled" @click="onBatchOperationClick('businessDeptBind')">批量绑定业务部门</a-button>
        <a-button  @click="onBatchOperationClick('activeAccount')">激活账号</a-button>
        <a-button v-if="activeTabKey == 'TT' || activeTabKey == 'GDT'" v-hasPermission="'VERIFY_DATA'" @click="onBatchOperationClick('verifyAccountData')">数据校准</a-button>
        <a-button v-if="activeTabKey == 'TT'" v-hasPermission="'SYNC_ADV_LOG'" @click="onBatchOperationClick('syncAdvLog')">同步操作日志</a-button>
        <a-button v-hasPermission="'AUTH_NEW_ACCOUNT'" type="primary" @click="addAccount()">新增账户</a-button>
        <!--        <a-button @click="operation({}, 'authorize')" type="primary">新增账户</a-button>-->
        <a-button v-if="activeTabKey == 'TT' || activeTabKey == 'GDT'" type="primary" @click="onBatchOperationClick('syncAccount')">同步账号</a-button>
        <a-button v-if="activeTabKey == 'TT'" type="primary" @click="onBatchOperationClick('syncAccountInfo')">同步账号信息</a-button>
        <a-button v-hasPermission="'MEDIA_CLEAR_MATERIAL'" v-if="activeTabKey == 'TT'" type="primary" @click="onBatchOperationClick('zjzh')">资金置换(授信转预付)</a-button>
        <a-button v-hasPermission="'MEDIA_CLEAR_MATERIAL'" v-if="activeTabKey == 'TT'" type="primary" @click="onBatchOperationClickFeishu('BzF0sT3XAhWDowtvN4cc2fRDnkh')">云文档资金置换-1</a-button>
        <a-button v-hasPermission="'MEDIA_CLEAR_MATERIAL'" v-if="activeTabKey == 'TT'" type="primary" @click="onBatchOperationClickFeishu('XlSks0ADphXyMYt8dAlc49zYnJb')">云文档资金置换-2</a-button>

        <!--        <a-button :disabled="batchBindPersonDisabled" @click="operation({}, 'total')" type="primary">总计</a-button>-->
<!--         <VerticalAlignBottomOutlined @click="download" />-->
<!--        <a-button v-if="undistributedBusiModeCount > 0" @click="filterTableData('industrymode')">业务模式待绑定({{ undistributedBusiModeCount }})</a-button>
        <a-button v-if="undistributedProjectCount > 0" @click="filterTableData('project')">产品待绑定({{ undistributedProjectCount }})</a-button>
        <a-button v-if="undistributedOperationCount > 0" @click="filterTableData('operation')">运营待绑定({{ undistributedOperationCount }})</a-button>-->
      </a-space>
      <a-table class="media_account_table w100" size="small" :columns="mediaTableColumns" bordered row-key="advertiserId" :data-source="mediaTableData" :loading="tableLoading" :pagination="tablePagination" :scroll="{ y: 200 }" :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }" @change="onTableChange" :custom-row="record => customClickRow(record)">
        <template #distribution="{ record }">
          <a-space v-hasPermission="'MEDIA_ADDBINDACCOUNT'">
            <a @click.stop="onDistributionBtnClick(record)">分配</a>
            <a v-hasPermission="'COPY_ACCOUNT'" v-if="activeTabKey == 'TT'" @click="operation(record, 'copy')" class="mr10">复制</a>
<!--             <a @click.stop="operation(record, 'edit')">编辑</a>
             <a @click.stop="operation(record, 'detail')">详情</a>
          <a @click.stop="operation(record, 'delete')">删除</a>-->
            <span v-if="record.distributionState == 'DISTRIBUTION'" style="color: #ccc; margin-left: 5px">已分配</span>
          </a-space>
        </template>
        <template #priorityType="{ record }">
<!--           {{ record.priorityType == 'NO' ? '无消耗' : '七日内有消耗' }}-->
          {{ mapColumn(record.priorityType) }}
        </template>
        <template #industryModel="{ record }">
          {{ mapIndustryModel(record.industryModel) }}
        </template>
        <template #activeStatus="{ record }">
          {{ record.activeStatus == '1' ? '活跃' : '非活跃' }}
        </template>
      </a-table>
    </div>

    <div  v-show="activeTabKey === 'CL'"  class="table_wrapper">
      <a-space :size="10" class="table_operation">
        <a-button v-hasPermission="'ACCOUNT_MATERIAL_CL'" @click="onBatchOperationClick('clearMaterialTask')">批量清理素材-正在投放中的素材</a-button>
        <a-button v-hasPermission="'ACCOUNT_MATERIAL_CL'"  @click="onBatchOperationClick('clearMaterialMaterialStatus')">批量暂停广告素材-按计划暂停素材</a-button>
        <a-button v-hasPermission="'ACCOUNT_MATERIAL_CL'"  @click="onBatchOperationClick('clearMaterialVideoPause')">批量暂停素材-不会暂停自动化工具下的素材</a-button>
        <span style="margin-left: 30px">今日执行情况：{{clearMaterialStatus}}</span>
        <p class="text4">刷新倒计时: {{ countdownNum }} 秒</p>
      </a-space>

      <a-table v-show="filterConditions.selectType == null || filterConditions.selectType === 'account' " class="media_account_table w100" size="small" :columns="clTableColumns" bordered  row-key="key" :data-source="clMediaTableData" :loading="tableLoading" :pagination="tablePagination" :scroll="{ y: 200 }" :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }" @change="onClTableChange" :custom-row="record => customClickRow(record)">
      </a-table>

      <a-table v-show=" filterConditions.selectType === 'customer' " class="media_account_table w100" size="small" :columns="clCustomerTableColumns" bordered  row-key="key" :data-source="clMediaTableData" :loading="tableLoading" :pagination="tablePagination" :scroll="{ y: 200 }" :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }" @change="onClTableChange" :custom-row="record => customClickRow(record)">
      </a-table>

    </div>

    <a-modal class="distributionProject" :mask-closable="false" v-model:visible="showDistributionModal" title="分配" @ok="confirmDistribution" ok-text="确认" cancel-text="取消">
      <a-form-item label="产品">
        <a-select show-search :filter-option="true" option-filter-prop="label" v-model:value="chooseProject" placeholder="请选择要分配的产品" allow-clear @select="onDistributionProjectSelect">
          <template #notFoundContent>
            <a-empty :image="simpleImage">
              <template #description>
                <span>暂无数据</span>
              </template>
            </a-empty>
          </template>
          <a-select-option :value="item.projectCode" v-for="item in distributionProject" :label="item.projectName" :key="item.id">{{ item.projectName }}</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="运营人员">
        <a-select v-model:value="choosePerson" show-search :filter-option="true" option-filter-prop="label" placeholder="请选择要分配的负责人" allow-clear>
          <template #notFoundContent>
            <a-empty :image="simpleImage">
              <template #description>
                <span>暂无数据</span>
              </template>
            </a-empty>
          </template>
          <a-select-option :value="item.userId" v-for="item in distributionPerson" :label="item.userCode" :key="item.id">{{ item.userCode }}</a-select-option>
        </a-select>
      </a-form-item>
    </a-modal>

    <!-- 新增账户对话框巨量引擎 -->
    <a-modal v-model:visible="addAccountVisibleTT" width="684px" title="授权方式" :okText="addAccountOkText" @ok="addAccountOk">
      <div style="margin-top: 15px;">
        <section>
          <a-row type="flex" align="middle">
            <a-col :span="22">
              <a-form-item label="选择授权APPID">
                <a-select v-model:value="appId" style="width: 380px;font-size: 14px;height: 32px; line-height: 32px; margin-right: 10px; align-content: center; " aria-required='true' placeholder="选择授权APPID" allow-clear :max-tag-count="1" :show-arrow="true" @change="appIdChange" :disabled="tableLoading">
                  <a-select-option value="1778172493389837">需要充值，转账，退款等操作</a-select-option>
                  <a-select-option value="1685415413328910">普通操作</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>
        </section>
      </div>
      <span style="width: 80px;font-size: 14px;height: 32px; line-height: 32px; margin-right: 10px; align-content: center; ">授权方式</span>
      <a-radio-group v-model:value="addAccountValue" @change="changeAddAccount">
        <a-radio-button value="a" style="width: 246px;height: 86px;margin-right: 35px;font-size: 13px;"><p>自主授权</p>适用于在已知账户邮箱和密码的情况</a-radio-button>
        <a-radio-button value="b" style="width: 246px;height: 86px; font-size: 13px;"><p>邀请他人授权</p>适用于在已知账户邮箱和密码的情况</a-radio-button>
      </a-radio-group>
      <div style="margin-top: 15px;" v-if="addAccountValue == 'b'">
        <span style="width: 80px;font-size: 14px;height: 32px;  margin-right: 10px; ">授权链接</span>
        <a-textarea v-model:value="authorityLink" ref="authorityLink" style="width: 556px; height: 178px; font-size: 14px;" disabled />
        <a-alert message="授权链接生成后，将于15分钟后失效，请尽快使用" type="warning" show-icon style="width: 556px;height: 48px; margin-left: 66px; margin-top: 20px; color: #e6a23c; font-size: 13px;" />
        <a-alert message="授权时请勾选：允许开发者管理敏感物料，否则可能会导致部分素材管理失败。" type="warning" show-icon style="width: 556px;height: 48px; margin-left: 66px; margin-top: 20px; color: #e6a23c; font-size: 13px;" />
      </div>
    </a-modal>
    <!-- 新增账户对话框腾讯广告 -->
    <a-modal v-model:visible="addAccountVisibleGDT" width="684px" title="授权方式" :okText="addAccountOkText" @ok="addAccountOk">
      <div style="margin-bottom: 15px;">
        <span style="width: 80px;font-size: 14px;height: 32px; line-height: 32px; margin-right: 10px; align-content: center; ">授权类型</span>
        <a-radio-group v-model:value="GDTValue" button-style="solid" @change="GDTValueChange">
          <a-radio-button value="wx">微信</a-radio-button>
          <a-radio-button value="qq">QQ</a-radio-button>
        </a-radio-group>
      </div>
      <span style="width: 80px;font-size: 14px;height: 32px; line-height: 32px; margin-right: 10px; align-content: center; ">授权方式</span>
      <a-radio-group v-model:value="addAccountValue" @change="changeAddAccount">
        <a-radio-button value="a" style="width: 246px;height: 86px;margin-right: 35px;font-size: 13px;"><p>自主授权</p>适用于在已知账户邮箱和密码的情况</a-radio-button>
        <a-radio-button value="b" style="width: 246px;height: 86px; font-size: 13px;"><p>邀请他人授权</p>适用于在已知账户邮箱和密码的情况</a-radio-button>
      </a-radio-group>
      <div style="margin-top: 15px;" v-if="addAccountValue == 'b'">
        <span style="width: 80px;font-size: 14px;height: 32px;  margin-right: 10px; ">授权链接</span>
        <a-textarea v-model:value="authorityLink" ref="authorityLink" style="width: 556px; height: 178px; font-size: 14px;" disabled />
      </div>
    </a-modal>
    <!-- 新增账户对话框磁力引擎 -->
    <a-modal v-model:visible="addAccountVisibleKS" width="684px" title="授权方式" :okText="addAccountOkText" @ok="addAccountOk">
      <span style="width: 80px;font-size: 14px;height: 32px; line-height: 32px; margin-right: 10px; align-content: center; ">授权方式</span>
      <a-radio-group v-model:value="addAccountValue" @change="changeAddAccount">
        <a-radio-button value="a" style="width: 246px;height: 86px;margin-right: 35px;font-size: 13px;"><p>自主授权</p>适用于在已知账户邮箱和密码的情况</a-radio-button>
        <a-radio-button value="b" style="width: 246px;height: 86px; font-size: 13px;"><p>邀请他人授权</p>适用于在已知账户邮箱和密码的情况</a-radio-button>
      </a-radio-group>
      <div style="margin-top: 15px;" v-if="addAccountValue == 'b'">
        <span style="width: 80px;font-size: 14px;height: 32px;  margin-right: 10px; ">授权链接</span>
        <a-textarea v-model:value="authorityLink" ref="authorityLink" style="width: 556px; height: 178px; font-size: 14px;" disabled />
      </div>
    </a-modal>
    <!-- 新增账户对话框巨量千川 -->
    <a-modal v-model:visible="addAccountVisibleQC" width="684px" title="授权方式" :okText="addAccountOkText" @ok="addAccountOk">
      <span style="width: 80px;font-size: 14px;height: 32px; line-height: 32px; margin-right: 10px; align-content: center; ">授权方式</span>
      <a-radio-group v-model:value="addAccountValue" @change="changeAddAccount">
        <a-radio-button value="a" style="width: 246px;height: 86px;margin-right: 35px;font-size: 13px;"><p>自主授权</p>适用于在已知账户邮箱和密码的情况</a-radio-button>
        <a-radio-button value="b" style="width: 246px;height: 86px; font-size: 13px;"><p>邀请他人授权</p>适用于在已知账户邮箱和密码的情况</a-radio-button>
      </a-radio-group>
      <div style="margin-top: 15px;" v-if="addAccountValue == 'b'">
        <span style="width: 80px;font-size: 14px;height: 32px;  margin-right: 10px; ">授权链接</span>
        <a-textarea v-model:value="authorityLink" ref="authorityLink" style="width: 556px; height: 178px; font-size: 14px;" disabled />
        <a-alert message="授权链接生成后，将于15分钟后失效，请尽快使用" type="warning" show-icon style="width: 556px;height: 48px; margin-left: 66px; margin-top: 20px; color: #e6a23c; font-size: 13px;" />
        <a-alert message="授权时请勾选：允许开发者管理敏感物料，否则可能会导致部分素材管理失败。" type="warning" show-icon style="width: 556px;height: 48px; margin-left: 66px; margin-top: 20px; color: #e6a23c; font-size: 13px;" />
      </div>
    </a-modal>

    <total-detail :row-data="rowData" ref="totalDetail"></total-detail>
    <row-detail :row-data="rowData" ref="rowDetail"></row-detail>
    <edit-media-account-modal :account-data="accountData" :user-list="distributionPerson" ref="editMediaAccountModal" @after-save="afterSave"></edit-media-account-modal>
    <copy-media-account-modal :row-data="rowData" ref="copyMediaAccountModal"></copy-media-account-modal>
    <account-authorize ref="accountAuthorize"></account-authorize>
    <assign-one-account ref="assignOneAccount" @after-save="afterSave" :account-data="rowData" :product-list="getChannelProjectList" :person-list="distributionPerson"></assign-one-account>
    <assign-many-account ref="assignManyAccount" @after-save="afterSave" :clear-material-types="clearMaterialTypes" :select-type="filterConditions.selectType" :agent-id="filterConditions.agentId" :customer-name="filterConditions.customerName" :active-tab-key="activeTabKey" :account-list="selectedRows" :product-list="getChannelProjectList" :person-list="distributionPerson"></assign-many-account>
    <verify-data ref="verifyData"></verify-data>
    <sync-adv-log ref="syncAdvLog"></sync-adv-log>
    <a-modal
      title="资金置换"
      v-model:visible="feishuExcelVisible"
      :confirm-loading="feishuExcelConfirmLoading"
      :ok-button-props="feishuOkButtonDisabled"
      :cancel-button-props="feishuCancelButtonDisabled"
      :cancel-text="cancelText"
      :ok-text="okText"
      @ok="feishuExcelhandleOk" @cancel="feishuExcelhandleCancel"
    >
      {{excelurlName}}<a :href="excelurl" target="_blank">飞书云文档资链接</a>
      <p></p>
      <p style="margin-top: 20px;">{{feishuMsg}}</p>
    </a-modal>
  </div>
</template>

<script>
import moment, { Moment } from 'moment';
import locale from 'ant-design-vue/lib/locale-provider/zh_CN';
import { message, Modal } from 'ant-design-vue';
import {
  getMediaAccountListData,
  confirmPrjectDistribution,
  getUserList,
  getFacilitatorListData,
  getAgentList,
  getProjectListData,
  activeAccountData,
  getCustomersInvoiceListFromMda,syncAccountDataInfo,
  getManagerAccountListData,
  getMaterialFileRebateMaterialSearchData,
  getMaterialFileRebateMaterialSearchDataUrlFromAdb,
  getClearMaterialTypes,
} from '@/api/mediaAccount/mediaAccount.js';
import { getCustomerDistinctListData } from '@/api/customer/customer.js';
import { Empty } from 'ant-design-vue';
// import { VerticalAlignBottomOutlined } from '@ant-design/icons-vue';
import TotalDetail from './TotalDetail.vue';
import RowDetail from './RowDetail.vue';
import EditMediaAccountModal from './editMediaAccountModal.vue';
import CopyMediaAccountModal from './copyMediaAccountModal.vue';
import AccountAuthorize from './AccountAuthorize.vue';
import AssignOneAccount from './AssignOneAccount.vue';
import AssignManyAccount from './AssignManyAccount.vue';
import VerifyData from './VerifyData.vue';
import { mapState } from 'vuex';
import axios from '@/utils/axios';
import { defineComponent, ref } from 'vue';
import SyncAdvLog from '@/views/mediaAccount/SyncAdvLog.vue';
// import { v4 as uuidv4 } from 'uuid'


export default {
  components: { SyncAdvLog, TotalDetail, RowDetail, EditMediaAccountModal, AccountAuthorize, AssignOneAccount, AssignManyAccount,VerifyData,CopyMediaAccountModal,},
  data() {
    // 从推广管理账户批量分配账号带过来的数据
    const activeTabKey = this.$route?.params?.channel || 'TT';
    const mdaIds = this.$route?.params?.ids?.join(' ') || '';
    // const projectCode= this.$route?.params?.productId || '';
    // 从推广管理账户未绑定产品账号名称带过来的数据
    const projectDistributionState = this.$route?.params?.projectDistributionState || null;
    return {
      excelurl1:"https://lmadart.feishu.cn/sheets/BzF0sT3XAhWDowtvN4cc2fRDnkh?sheet=qeNXKB",
      excelurl1Name:"飞书云文档地址一：",
      excelurl2:"https://lmadart.feishu.cn/sheets/XlSks0ADphXyMYt8dAlc49zYnJb?sheet=zZ1cYv",
      excelurl2Name:"飞书云文档地址二：",
      excelurl:null,
      excelurlName:null,
      cancelText:"关闭",
      okText:"提交",
      feishuOkButtonDisabled:{ disabled: false },
      feishuCancelButtonDisabled:{ disabled: false },
      feishuMsg: '确认执行【飞书云文档资金置换】任务吗？',
      feishuExcelVisible:false,
      feishuExcelflag:'',
      feishuExcelConfirmLoading: false,

      unmountedFlag: true,
      countdownNum:-1,
      clearMaterialStatus:"0/0",
      clearMaterialTypes:[],
      moment,
      managerAccountListTemp:[],
      businessDeptList:[
        {name:'业务一部',value:'业务一部'},
        {name:'业务二部',value:'业务二部'},
        {name:'业务三部',value:'业务三部'},
        {name:'业务四部',value:'业务四部'},
        {name:'业务五部',value:'业务五部'},
        {name:'业务六部',value:'业务六部'},
        {name:'圣达际',value:'圣达际'},
        {name:'洽尔',value:'洽尔'},
        {name:'MCN',value:'MCN'},
        {name:'其他',value:'其他'},
      ],
      activeTabKey: activeTabKey,
      locale,
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
      distributionOperatorDisabled: true, //会话框运营人员禁用
      tableLoading: false,
      xactiveTabKey: activeTabKey,
      chooseProject: null, // 分配的产品Code
      chooseProjectName: '',
      choosePerson: null, // 选择分配负责人
      getChannelProjectList: [],
      distributionProject: [], //选择分配产品的下拉列表
      distributionPerson: [], //选择分配负责人的下拉列表
      facilitatorList: [], //服务商下拉列表
      agentList: [], //二代下拉列表
      distributionParams: {}, //确认媒体分配产品所需要携带的参数
      projectListArray: [], //产品列表
      selectedRowKeys: [], // 选中的行的id
      selectedRows: [],
      companyNameOptions: [], // 客户名称下拉列表
      invoiceNameOptions: [], // 开户主体下拉列表
      showDistributionModal: false,
      undistributedProjectCount: 1, // 未分配产品账号数量
      undistributedOperationCount: 1, // 未分配运营账号数量
      undistributedBusiModeCount: 1, // 未分配业务模式账号数量
      addAccountVisibleTT: false,
      addAccountVisibleGDT: false,
      addAccountVisibleKS: false,
      addAccountVisibleQC: false,
      addAccountValue: 'a',
      GDTValue: 'wx',
      addAccountOkText: '确认',
      authorityLink: "",
      appId:"",
      authTTUrl:"",
      ttUrl: "https://open.oceanengine.com/audit/oauth.html?app_id=[appid]&state=[appid]&scope=[scope]&material_auth=1&redirect_uri=https://tias.plus/extractor/tt/accessToken/init",
      gdtWxURL: "https://developers.e.qq.com/oauth/authorize?client_id=1111459058&redirect_uri=https%3A%2F%2Ftias.plus%2Fextractor%2Fgdt%2FaccessToken%2Finit%2F&state=custom_params&account_type=ACCOUNT_TYPE_WECHAT",
      gdtQqURL: "https://developers.e.qq.com/oauth/authorize?client_id=1111459058&redirect_uri=https%3A%2F%2Ftias.plus%2Fextractor%2Fgdt%2FaccessToken%2Finit%2F&state=custom_params&account_type=ACCOUNT_TYPE_QQ",
      ksUrl: "https://developers.e.kuaishou.com/tools/authorize?app_id=165900726&state=kuaishou_165900726_eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE2ODYwMjU2ODUsImlhdCI6MTY4NjAyMzg4NSwiaXNzIjoicGx1dHVzIiwiZGF0YSI6eyJpZCI6MzEsIm5hbWUiOiJEZW1vXHU4ZDI2XHU1M2Y3IiwiY2lkIjoxLCJlbWFpbCI6ImRlbW8zQHBsdXR1cy5jb20iLCJpc19zdXBlcnVzZXIiOmZhbHNlfX0.sBvGHPJdZJbHzxxYeO-hSXHKigsMmes_VBl4y9AfpgA&scope=%5B%22ad_query%22%2C%22ad_manage%22%2C%22report_service%22%2C%22public_dmp_service%22%2C%22public_agent_service%22%2C%22public_account_service%22%5D&oauth_type=advertiser&redirect_uri=https%3A//backend.gravity-engine.com/api/v1/base/media/account/oauth2/callback/",
      qcUrl: "https://qianchuan.jinritemai.com/openapi/qc/audit/oauth.html?app_id=1700351401407511&is_new_connect=0&is_new_user=0&material_auth=1&state=1700351401407511&redirect_uri=https://tias.plus/extractor/qc/accessToken/init/&rid=caydsfpicec&scope=[20120000,22000000,23000000,20110000,24000000,21000000,25000000]",
      tablePagination: {
        limit: '30',
        page: '1',
        pageSize: 30,
        showTotal: total => `总计${total}条数据`,
        showSizeChanger: true,
        pageSizeOptions: ['30', '50', '100', '500', '1000','2000'],
      },
      isConsume: [
        { text: '无消耗', value: 'NO' },
        { text: '七日内有消耗', value: 'SEVENDAY' },
        { text: '当日新增', value: 'NEW' },
      ],
      mediaTableColumns: [
        {
          title: '账号ID',
          dataIndex: 'advertiserId',
          key: 'advertiserId',
        },
        {
          title: '账号名称',
          dataIndex: 'advertiserName',
          key: 'advertiserName',
        },
        {
          title: '客户名称',
          dataIndex: 'customerOfCompanyName',
          key: 'customerOfCompanyName',
        },
        {
          title: '产品名称',
          key: 'projectName',
          dataIndex: 'projectName',
        },
        {
          title: '开户主体',
          key: 'invoiceName',
          dataIndex: 'invoiceName',
        },
        {
          title: '业务模式',
          key: 'industryModel',
          dataIndex: 'industryModel',
          width: 160,
          slots: {
            customRender: 'industryModel',
          },
        },
        {
          title: '活跃状态',
          key: 'activeStatus',
          dataIndex: 'activeStatus',
          slots: {
            customRender: 'activeStatus',
          },
        },
        {
          title: '是否消耗',
          key: 'priorityType',
          dataIndex: 'priorityType',
          slots: {
            customRender: 'priorityType',
          },
        },
        {
          title: '运营',
          key: 'operaterName',
          dataIndex: 'operaterName',
        },
        {
          title: '服务商',
          key: 'facilitatorName',
          dataIndex: 'facilitatorName',
        },
        {
          title: '二代',
          key: 'agentName',
          dataIndex: 'agentName',
        },
        {
          title: '所属业务部门',
          key: 'businessDept',
          dataIndex: 'businessDept',
        },
        {
          title: '今日消耗',
          key: 'todayCost',
          dataIndex: 'todayCost',
        },
        {
          title: '昨日消耗',
          key: 'yesCost',
          dataIndex: 'yesCost',
        },
        {
          title: '最近绑定人',
          key: 'binder',
          dataIndex: 'binder',
        },
        // {
        //   title: '消耗',
        //   key: '',
        //   dataIndex: '',
        //   width: 60,
        // },
        // {
        //   title: '当前余额',
        //   key: '',
        //   dataIndex: '',
        //   width: 80,
        // },
        // // 大于5标红；有余额无消耗的蓝色
        // {
        //   title: '余额利用率',
        //   key: '',
        //   dataIndex: '',
        //   width: 100,
        // },
        {
          title: '操作',
          key: 'distribution',
          dataIndex: 'distribution',
          width: 160,
          slots: {
            customRender: 'distribution',
          },
        },
      ],
      clTableColumns: [
        {
          title: '月份',
          dataIndex: 'periodMonth',
          key: 'periodMonth',
        },
        {
          title: '账号ID',
          dataIndex: 'advertiserId',
          key: 'advertiserId',
        },
        {
          title: '代理商ID',
          dataIndex: 'agentId',
          key: 'agentId',
        },
        {
          title: '代理商名称',
          dataIndex: 'agentName',
          key: 'agentName',
        },
        {
          title: '客户名称',
          dataIndex: 'customerName',
          key: 'customerName',
        },
        {
          title: '无效素材数量',
          dataIndex: 'materialCounts',
          key: 'materialCounts',
        },
        {
          title: '清理素材数量',
          dataIndex: 'clMaterialCounts',
          key: 'clMaterialCounts',
        },
        {
          title: '失败素材数量',
          dataIndex: 'fclMaterialCounts',
          key: 'fclMaterialCounts',
        },
        {
          title: '计划数量',
          dataIndex: 'promotionCounts',
          key: 'promotionCounts',
        },
        {
          title: '清理计划数量',
          dataIndex: 'clPromotionCounts',
          key: 'clPromotionCounts',
        },
        {
          title: '失败计划数量',
          dataIndex: 'fclPromotionCounts',
          key: 'fclPromotionCounts',
        },
        {
          title: '失败原因',
          dataIndex: 'bz',
          key: 'bz',
          slots: { customRender: 'bz' },
          ellipsis: true,
          width: 200,
        },
        // {
        //   title: '是否有效素材',
        //   dataIndex: 'isValidVideoMaterial',
        //   key: 'isValidVideoMaterial',
        //   slots: {
        //     customRender: 'isValidVideoMaterial',
        //   },
        // },
        // {
        //   title: '素材名称',
        //   key: 'projectName',
        //   dataIndex: 'projectName',
        // },
        // {
        //   title: '关联广告ID',
        //   key: 'material_info.promotion_ids',
        //   dataIndex: 'material_info.promotion_ids',
        // },
        // {
        //   title: '消耗',
        //   key: 'cost',
        //   dataIndex: 'cost',
        //   width: 160,
        // },
        // {
        //   title: '政策消耗',
        //   key: 'policyCost',
        //   dataIndex: 'policyCost',
        //   width: 160,
        // },
        {
          title: '更新日期',
          key: 'updateDate',
          dataIndex: 'updateDate',
          width: 160,
        },
        {
          title: '清理人员姓名',
          key: 'operatorUserName',
          dataIndex: 'operatorUserName',
          width: 160,
        },
        {
          title: '最近操作时间',
          key: 'operatorTime',
          dataIndex: 'operatorTime',
          width: 160,
        },
      ],
      clCustomerTableColumns: [
        {
          title: '月份',
          dataIndex: 'periodMonth',
          key: 'periodMonth',
        },
        {
          title: '客户名称',
          dataIndex: 'customerName',
          key: 'customerName',
        },
        {
          title: '账号数量',
          dataIndex: 'advertiserCount',
          key: 'advertiserCount',
        },
        {
          title: '代理商ID',
          dataIndex: 'agentId',
          key: 'agentId',
        },
        {
          title: '代理商名称',
          dataIndex: 'agentName',
          key: 'agentName',
        },

        {
          title: '无效素材数量',
          dataIndex: 'materialCounts',
          key: 'materialCounts',
        },
        {
          title: '清理素材数量',
          dataIndex: 'clMaterialCounts',
          key: 'clMaterialCounts',
        },
        {
          title: '失败素材数量',
          dataIndex: 'fclMaterialCounts',
          key: 'fclMaterialCounts',
        },
        {
          title: '计划数量',
          dataIndex: 'promotionCounts',
          key: 'promotionCounts',
        },
        {
          title: '清理计划数量',
          dataIndex: 'clPromotionCounts',
          key: 'clPromotionCounts',
        },
        {
          title: '失败计划数量',
          dataIndex: 'fclPromotionCounts',
          key: 'fclPromotionCounts',
        },
        {
          title: '失败原因',
          dataIndex: 'bz',
          key: 'bz',
          slots: { customRender: 'bz' },
          ellipsis: true,
          width: 200,
        },
        // {
        //   title: '是否有效素材',
        //   dataIndex: 'isValidVideoMaterial',
        //   key: 'isValidVideoMaterial',
        //   slots: {
        //     customRender: 'isValidVideoMaterial',
        //   },
        // },
        // {
        //   title: '素材名称',
        //   key: 'projectName',
        //   dataIndex: 'projectName',
        // },
        // {
        //   title: '关联广告ID',
        //   key: 'material_info.promotion_ids',
        //   dataIndex: 'material_info.promotion_ids',
        // },
        // {
        //   title: '消耗',
        //   key: 'cost',
        //   dataIndex: 'cost',
        //   width: 160,
        // },
        // {
        //   title: '政策消耗',
        //   key: 'policyCost',
        //   dataIndex: 'policyCost',
        //   width: 160,
        // },
        {
          title: '更新日期',
          key: 'updateDate',
          dataIndex: 'updateDate',
          width: 160,
        },
        {
          title: '清理人员姓名',
          key: 'operatorUserName',
          dataIndex: 'operatorUserName',
          width: 160,
        },
        {
          title: '最近操作时间',
          key: 'operatorTime',
          dataIndex: 'operatorTime',
          width: 160,
        },
      ],
      testText:'1111111',
      mediaTableData: [],
      clMediaTableData:[],
      // 筛选符合条件的媒体账户所需要的参数
      filterConditions: {
        companyBindState:null,
        selectType:'account',
        yesterdayMinCost:null,
        todayMinCost:null,
        operatorId: null,
        facilitatorId: null,
        mdaIds: mdaIds,
        mdaNames: '',
        industryModelBindState:null,
        projectDistributionState: projectDistributionState,
        //素材清理
        agentId: 1775646369326147,//代理商主账户
        period: moment(new Date()).format("YYYY-MM") ,//素材清理月份
        advertiserId:null,//账户
        customerName:null,
        materialTags:null,
        materialIsEffective:null,
      },
      labelCol: { sm: { span: 8 }, md: { span: 8 }, lg: { span: 8 }, xl: { span: 8 }, xxl: { span: 6 } },
      wrapperCol: { sm: { span: 14 }, md: { span: 14 }, lg: { span: 14 }, xl: { span: 14 }, xxl: { span: 16 } },
      rowData: {}, //当前行的数据
      accountData: [], //传给弹框的选中行数据
      industryModelList: [
        { value: 'Self', label: '二代' },
        { value: 'Substitute', label: '代运营' },
        { value: 'Service', label: '服务商' },
        { value: 'CusSelfOpera', label: '客户自运营' },
      ], // 业务模式
      filterConditionMap: {
        All: {
          INDUSTRYMODEL_SHOW: true,
          PRODUCT_SHOW: true, // 产品
          PERSON_SHOW: true, // 服务商/运营
        },
        Self: {
          INDUSTRYMODEL_SHOW: false,
          PRODUCT_SHOW: false, // 产品
          PERSON_SHOW: false, // 服务商/运营
        },
        Substitute: {
          INDUSTRYMODEL_SHOW: false,
          PRODUCT_SHOW: true,
          PERSON_SHOW: true,
        },
        Service: {
          INDUSTRYMODEL_SHOW: false,
          PRODUCT_SHOW: true,
          PERSON_SHOW: true,
        },
        CusSelfOpera:{
          INDUSTRYMODEL_SHOW: true,
          PRODUCT_SHOW: true, // 产品
          PERSON_SHOW: true, // 服务商/运营
        }
      },
    };
  },
  provide() {
    return {
      getAgentList: () => this.agentList,
      getFacilitatorList: () => this.facilitatorList,
    };
  },
  inject: ['$bus'],
  computed: {
    periodComputed(){
     return  moment(new Date()).format("YYYY-MM") ;
    },
    ...mapState({ userJobs: state => state.oauth.userInfo.jobs }),
    funcPoints() {
      return localStorage.getItem('funcPoint');
    },
    filterConditionShow() {
      if (!this.filterConditions.industryModel || this.filterConditions.industryModel === '') {
        return this.filterConditionMap.All;
      } else {
        return this.filterConditionMap[this.filterConditions.industryModel];
      }
    },
    //选中判断同一个客户下的产品是否同一个
    selectedRowsIsNotSameProduct() {
      let firstRow = this.selectedRows[0];
      return this.selectedRows.some(item => {
        return item.projectName != firstRow.projectName;
      });
    },
    //选中判断是否是同一个客户
    selectRowsIsNotSameCustomer() {
      let firstRow = this.selectedRows[0];
      return this.selectedRows.some(item => {
        return item.customerOfCompanyName != firstRow.customerOfCompanyName;
      });
    },
    batchBindIndustryDisabled() {
      if (this.selectedRows.length <= 0) {
        return true;
      }
      return false;
    },
    batchBindProjectDisabled() {
      return (
        this.selectedRows.length <= 0 ||
        this.selectRowsIsNotSameCustomer ||
        this.selectedRows.some(item => {
          return item.industryModel == null;
        }) ||
        this.selectedRows.some(item => {
          return item.industryModel == 'Self';
        })
      );
    },
    batchBindPersonDisabled() {
      if (this.selectedRows.length <= 0) {
        return true;
      }
      let result = this.selectedRows.some(item => {
        return item.industryModel == null;
      });
      return result;
    },
  },
  created() {
    this.longPolling();
    this.getMediaAccountList();
    this.getProjectList();
    this.userList();
    this.getCustomerList();
    this.getCustomersList();
    this.getFacilitatorList();
    this.getAgentList();
    this.getManagerAccountListTemp();
    this.getClearMaterialTypesList();
    this.$bus.on('refreshAgentList', mode => {
      if (mode == 'Self') {
        this.getAgentList();
      } else {
        this.getFacilitatorList();
      }
    });
  },
  methods: {
    feishuExcelhandleCancel(){
      this.feishuExcelVisible = false;
      this.feishuExcelFlag = '';
      this.feishuExcelConfirmLoading = false;
      this.feishuMsg = '确认执行【飞书云文档资金置换】任务吗？';
      this.feishuOkButtonDisabled = {disabled:false}
      this.feishuCancelButtonDisabled = {disabled:false}
    },
    feishuExcelhandleOk(){
      this.feishuCancelButtonDisabled = {disabled:true}
      this.feishuExcelConfirmLoading = true;
      this.feishuMsg = '【资金置换】任务已经提交，请耐心等待执行结果...';
      /// post feishu/excel_fund_exchange 超时时间10分钟
      axios.post('/feishu_recharge/excel_fund_exchange/'+this.feishuExcelflag)
        .then(res => {
          if (res.code == 0) {
            this.feishuExcelConfirmLoading = false;
            this.feishuMsg = '【资金置换】任务执行完成，请在在线文档中查看执行结果。';
            // message.success('资金置换任务已经提交，请耐心等待执行结果！');
            this.feishuOkButtonDisabled = { disabled: true };
            this.feishuCancelButtonDisabled = { disabled: false };
            // message.success('飞书云文档资金置换任务已经提交，请耐心等待执行结果！');
          } else {
            this.feishuExcelConfirmLoading = false;
            this.feishuMsg = '【资金置换】任务执行失败：'+res.msg;
            // message.success('资金置换任务已经提交，请耐心等待执行结果！');
            this.feishuOkButtonDisabled = { disabled: true };
            this.feishuCancelButtonDisabled = { disabled: false };
            // message.error('飞书云文档资金置换任务提交失败！');
          }
        })
        .catch((error => {
            this.feishuExcelConfirmLoading = false;
            this.feishuMsg = '【资金置换】任务提交失败,请关闭后重新提交！';
            // message.success('资金置换任务已经提交，请耐心等待执行结果！');
            this.feishuOkButtonDisabled = { disabled: true };
            this.feishuCancelButtonDisabled = { disabled: false };
          }

        ));
    },
    onBatchOperationClickFeishu(flag){
      this.feishuExcelVisible = true;
      this.feishuExcelflag = flag;
      if('BzF0sT3XAhWDowtvN4cc2fRDnkh' === flag){
        this.excelurl = this.excelurl1;
        this.excelurlName = this.excelurl1Name;
      }else {
        this.excelurl = this.excelurl2;
        this.excelurlName = this.excelurl2Name;
      }

    },
    async sleepTime() {
      for (let i = 0; i < 15; i++) {
        await new Promise(resolve => setTimeout(resolve, 1000));
        this.countdownNum--;
      }
    },
    async longPolling() {
      // alert("向服务器发送长轮询请求！");
      if (this.countdownNum < 0) {
        this.countdownNum = 15;
        while (this.countdownNum >= 0 && this.unmountedFlag ) {
          this.countdownNum = 15;
          // alert("向服务器发送长轮询请求！");
          const res = await axios.get('/clear/material/getExecStats');
          await this.sleepTime();
          this.clearMaterialStatus = res.msg || "0/0";
          console.error("向服务器发送长轮询请求！");
        }
      }

    },
    getClearMaterialTypesList(){
      getClearMaterialTypes().then(res => {
        if (res.code == 0) {
          this.clearMaterialTypes = res.data;
        }
      });
    },
    getManagerAccountListTemp(){
      var mediaChannelTemp = this.mediaChannel;
      if(this.activeTabKey == 'CL' ){
        mediaChannelTemp = 'TT';
      }
      getManagerAccountListData(mediaChannelTemp).then(res => {
        if (res.code == 0) {
          this.managerAccountListTemp  = res.data;
        }
      });
    },
    mapIndustryModel(val) {
      return this.industryModelList.filter(item => {
        return item.value == val;
      })[0]?.label;
    },
    mapColumn(key) {
      if (key) {
        return this.isConsume.filter(item => {
          return item.value == key;
        })[0].text;
      }
    },
    /**
     * @param {*} enterIdSearch id回车搜索
     * @return {*}
     */
    enterIdSearch() {
      this.tablePagination.page = '1';
      this.getMediaAccountList();
    },
    // 筛选下拉列表
    filterSelect(inputValue, option) {
      return option.children[0].children.indexOf(inputValue) >= 0;
    },
    /** 获取客户名称列表
     * @param {*}
     * @return {*}
     */
    async getCustomerList() {
      let res = await getCustomerDistinctListData({ channelType: this.activeTabKey });
      this.companyNameOptions = res.page.list.map(item => ({
        text: item.customerOfCompanyName,
        value: item.companyCode,
      }));
    },
    /** 获取开户主体列表
     * @param {*}
     * @return {*}
     */
    async getCustomersList() {
      // let res = await getCustomersInvoiceList({});
      let res = await getCustomersInvoiceListFromMda({});
      this.invoiceNameOptions = res.page;
    },
    /** 客户名称下拉更改
     * @param {*}
     * @return {*}
     */
    onCompanyNameChange() {
      // this.getInvoiceNameList();
    },
    /** 点击表格行
     * @param {*} record 点击的行数据
     * @return {*}
     */
    customClickRow(record) {
      return {
        onClick: event => {
          let keyIndex = this.selectedRowKeys.indexOf(record.advertiserId);
          if (keyIndex !== -1) {
            this.selectedRowKeys.splice(keyIndex, 1);
          } else {
            this.selectedRowKeys.push(record.advertiserId);
          }

          let rowIndex = this.selectedRows.findIndex(ele => ele.advertiserId === record.advertiserId);
          if (rowIndex !== -1) {
            this.selectedRows.splice(rowIndex, 1);
          } else {
            this.selectedRows.push(record);
          }
        },
      };
    },
    onCompanyBindStateChange(value) {
      if (value === 'NOBIND') {
        this.filterConditions.companyCode = undefined;
      }
    },
    onProjectChange(projectCode) {
      if (projectCode) {
        this.filterConditions.projectCode = projectCode;
        this.filterConditions.distributionState = 'DISTRIBUTION';
      } else {
        this.filterConditions.projectCode = null;
      }
    },
    changeStatus(value, type) {
      if (value && value == 'NODISTRIBUTION') {
        this.filterConditions[type] = null;
      }
    },
    onPersonChange(value, type) {
      if (value) {
        this.filterConditions[type] = value;
      } else {
        this.filterConditions[type] = null;
      }
    },
    onPriorityTypeChange() {
      this.getMediaAccountList();
    },
    onActiveAccountChange(){
      this.getMediaAccountList();
    },
    onTabChange() {
      this.selectedRowKeys = [];
      this.selectedRows = [];
      if(this.activeTabKey === 'CL' ){
        this.getManagerAccountListTemp();
        this.resetFilter();
        this.getMaterialFileRebateMaterialSearchDataFromAdb();
        return ;
      }
      this.getProjectList();
      this.resetFilter();
      this.getCustomerList();

    },
    // 会话框选择产品
    getProjectList() {
      getProjectListData({
        channelType: this.activeTabKey,
      }).then(res => {
        this.$nextTick(() => {
          this.getChannelProjectList = res.list;
          if (this.mediaTableColumns[1] && this.mediaTableColumns[1].title === '账号名称') {
            if (this.activeTabKey !== 'TT') {
              this.mediaTableColumns.splice(1, 1);
            }
          } else if (this.activeTabKey === 'TT') {
            this.mediaTableColumns.splice(1, 0, {
              title: '账号名称',
              dataIndex: 'advertiserName',
              key: 'advertiserName',
            });
          }
        });
      });
    },
    userList() {
      let params = {
        jobs: 'OPERATION',
      };
      getUserList(params).then(res => {
        if (res.code == 0) {
          this.distributionPerson = res.page.list;
        }
      });
    },
    getFacilitatorList() {
      getFacilitatorListData({}).then(res => {
        if (res.code == 0) {
          this.facilitatorList = res.page.list;
        }
      });
    },
    getAgentList() {
      getAgentList({}).then(res => {
        if (res.code == 0) {
          this.agentList = res.list || [];
        }
      });
    },
    // 打开会话框
    async onDistributionBtnClick(record) {
      this.rowData = record;
      this.$nextTick(() => {
        this.$refs.assignOneAccount.visible = true;
      });
    },
    //批量分配产品选择框选择事件
    onDistributionProjectSelect(value, option) {
      this.chooseProjectName = option.label;
    },
    // 确认分配
    confirmDistribution() {
      if (!this.chooseProject && !this.choosePerson) {
        this.$message.error('请分配具体的产品或运营人员!');
        return false;
      }
      this.distributionParams.projectCode = this.chooseProject;
      this.distributionParams.projectName = this.chooseProjectName;
      this.distributionParams.operaterId = this.choosePerson;
      let operaterItem = this.distributionPerson.find(item => item.userId == this.choosePerson);
      let operaterName = operaterItem && operaterItem.userCode;

      this.distributionParams.operaterName = operaterName;
      this.distributionParams.mdaccountIdList = this.accountData.map(item => item.advertiserId);
      if (!this.distributionParams.operaterId && !this.distributionParams.projectCode) {
        message.warning('请分配具体的产品或运营人员');
        return;
      }
      confirmPrjectDistribution(this.distributionParams).then(res => {
        if (res.code === 0) {
          this.$message.success('分配成功');
          this.getMediaAccountList();
        }
        this.showDistributionModal = false;
      });
    },
    syncAccountInfo(){
      if (this.tableLoading === true) {
        return;
      }
      let mdaccountIdList = this.selectedRows.map(item => item.advertiserId);
      if(mdaccountIdList.length==0) {
        this.$message.warning('请选择需要同步信息的账号！');
        return;
      }
      syncAccountDataInfo(this.activeTabKey,mdaccountIdList).then(res => {
        if (res.code === 0) {
          this.$message.success('同步成功');
          this.getMediaAccountList();
        }else {
          this.$message.error(res.msg);
        }
      });
      console.error("syncAccountInfo")
    },
    activeAccount(){
      if (this.tableLoading === true) {
        return;
      }
      let mdaccountIdList = this.selectedRows.map(item => item.advertiserId);
      if(mdaccountIdList.length==0) {
        this.$message.warning('请选择需要激活的账号！');
        return;
      }
      let params = {
        mediaAccountIdList: mdaccountIdList,
      };
      activeAccountData(this.activeTabKey,params).then(res => {
        if (res.code === 0) {
          this.$message.success('激活成功');
          this.getMediaAccountList();
        }else {
          this.$message.error(res.msg);
        }
      });
      console.error("activeAccount")
    },
    getMediaAccountList() {
      if (this.tableLoading === true) {
        return;
      }
      this.tableLoading = true;
      this.filterConditions.mdaIds = this.filterConditions.mdaIds.trim();
      this.filterConditions.mdaNames = this.filterConditions.mdaNames.trim();

      let params = {
        mediaChannel: this.activeTabKey,
        ...this.tablePagination,
        ...this.filterConditions,
      };

      if (this.filterConditions.industryModel == '') {
        // params.industryModel = 'NULL,Service,Substitute';
        // if (this.funcPoints.includes('MEDIA_FILTER_INDUSTRY_SELF')) {
        //   params.industryModel += ',Self';
        // }
        // params.industryModel += '';
        delete params.industryModel;
      }

      getMediaAccountListData(params).then(res => {
        if (res.code == 0) {
          this.mediaTableData = res.page.list;
          this.tablePagination.current = res.page.currPage;
          this.tablePagination.pageSize = res.page.pageSize;
          this.tablePagination.total = res.page.totalCount;
        }
        this.tableLoading = false;
      });
    },
    // 媒体账号表格
    onTableChange(pagination) {
      this.selectedRowKeys = [];
      this.selectedRows = [];
      this.tablePagination.page = pagination.current.toString();
      this.tablePagination.limit = pagination.pageSize.toString();
      this.tablePagination.pageSize = pagination.pageSize;
      this.getMediaAccountList();
    },
    // 媒体账号表格
    onClTableChange(pagination) {
      this.selectedRowKeys = [];
      this.selectedRows = [];
      this.tablePagination.page = pagination.current.toString();
      this.tablePagination.limit = pagination.pageSize.toString();
      this.tablePagination.pageSize = pagination.pageSize;
      // this.getMaterialFileRebateMaterialSearch();
      this.getMaterialFileRebateMaterialSearchDataFromAdb();
    },
    onSearchBtnClick() {
      this.selectedRowKeys = [];
      this.selectedRows = [];
      if (this.tablePagination.page !== '1') {
        this.tablePagination = {};
        this.tablePagination.limit = '10';
        this.tablePagination.page = '1';
      }
      if(this.activeTabKey == 'CL' ){
        // this.getMaterialFileRebateMaterialSearch();
        this.getMaterialFileRebateMaterialSearchDataFromAdb();
      }else {
        this.getMediaAccountList();
      }
    },
    resetFilter() {
      this.selectedRowKeys = [];
      this.selectedRows = [];
      this.tablePagination.page = '1';
      this.filterConditions = {
        operatorId: null, mdaIds: '',
        mdaNames: '',
        selectType: 'account',
        // agentId: 1775646369326147,//代理商主账户
        period: moment(new Date()).format("YYYY-MM") ,//素材清理月份
        },
      this.getMediaAccountList();
    },

    /**
     * 相同客户 支持批量
     * 相同客户 不同客户显示提示
     * @param {*} selectedRowKeys 已选中的行
     * @return {*}
     */
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
    },
    // 下载
    download() {
      //
    },
    // 表格操作项点击
    operation(record, type) {
      this.rowData = record;
      switch (type) {
        case 'total': //总计
          this.$refs.totalDetail.modal.visible = true;
          break;
        case 'detail': //详情
          this.$refs.rowDetail.visible = true;
          break;
        case 'edit': //编辑
          if (!Array.isArray(record)) {
            this.accountData = [record];
          } else {
            this.accountData = this.selectedRows;
          }
          this.$refs.editMediaAccountModal.visible = true;
          break;
        case 'delete': //删除
          let _this = this;
          Modal.confirm({
            title: '删除',
            content: '该信息删除后将不予保留，请谨慎操作！',
            onOk() {
              _this.getMediaAccountList();
            },
            onCancel() {},
          });
          break;
        case 'authorize': //授权
          this.$refs.accountAuthorize.visible = true;
          break;
        case 'copy':
          this.$refs.copyMediaAccountModal.visible = true;
          break;
      }
    },
    // 保存编辑后回调
    async afterSave(data) {
      this.selectedRows = [];
      this.selectedRowKeys = [];
      await this.getMediaAccountList();
    },
    // 批量操作
    onBatchOperationClick(val) {
      this.$refs.assignManyAccount.mode = val;
      this.$refs.verifyData.mode = val;
      switch (val) {
        case 'zjzh': //资金置换(授信转预付)
          this.$refs.assignManyAccount.visible = true;
          break;
        case 'industrymode': //业务模式
          if (!this.checkIndustryModelStatus()) {
            this.$refs.assignManyAccount.visible = true;
          } else {
            message.warning('存在已分配业务模式的账号');
          }
          break;
        case 'product': //批量绑定产品
          const _this = this;
          if (this.checkProjectStatus()) {
            message.warning('存在已分配产品的账号');
            return;
          }
          if (this.selectedRowsIsNotSameProduct) {
            Modal.confirm({
              title: '提示',
              content: '请谨慎分配同一客户的不同产品！',
              onOk() {
                // _this.onDistributionBtnClick(null);
                _this.$refs.assignManyAccount.visible = true;
              },
              onCancel() {},
            });
          } else {
            // this.onDistributionBtnClick(null);
            this.$refs.assignManyAccount.visible = true;
          }
          break;
        case 'operation': //批量绑定运营
          // this.operation(this.selectedRows, 'edit');
          if (!this.checkOperationStatus()) {
            this.$refs.assignManyAccount.visible = true;
          } else {
            message.warning('存在已分配运营的账号');
          }
          break;
        case 'clearMaterial': //批量清理素材
          this.$refs.assignManyAccount.visible = true;
          break;
        case 'clearMaterialTask': //批量清理素材
          this.$refs.assignManyAccount.visible = true;
          break;
        case 'clearMaterialMaterialStatus': //批量清理素材
          this.$refs.assignManyAccount.visible = true;
          break;
        case 'clearMaterialVideoPause': //批量清理素材
          this.$refs.assignManyAccount.visible = true;
          break;
        case 'businessDeptBind': //批量清理账号
          this.$refs.assignManyAccount.visible = true;
          break;
        case 'syncAccount':
          this.$refs.assignManyAccount.mediaChannel = this.activeTabKey;
          this.$refs.assignManyAccount.managerAccountListTemp = this.managerAccountList;
          this.$refs.assignManyAccount.visible = true;
          break;
        case 'activeAccount':
          this.activeAccount();
          this.getMediaAccountList();
          break;
        case 'syncAccountInfo':
          this.syncAccountInfo();
          break;
        case 'verifyAccountData':
          if(this.selectedRowKeys.length<=0){
            this.$message.error('请选择需要数据校准的账号');
            return false;
          }
          this.$refs.verifyData.accountList = this.selectedRows.map(item => item.advertiserId);
          this.$refs.verifyData.businessMode = [...new Set(this.selectedRows.map(item => item.industryModel))];
          this.$refs.verifyData.mediaChannel = this.activeTabKey;
          this.$refs.verifyData.visible = true;
          break;
        case "syncAdvLog":
          if(this.selectedRowKeys.length<=0){
            this.$message.error('请选择需要同步日志的账号');
            return false;
          }
          this.$refs.syncAdvLog.mode = val;
          this.$refs.syncAdvLog.accountList = this.selectedRows.map(item => item.advertiserId);
          this.$refs.syncAdvLog.mediaChannel = this.activeTabKey;
          this.$refs.syncAdvLog.userId =  this.$store.state.oauth.userInfo.userId;
          this.$refs.syncAdvLog.visible = true;
          break;
      }
    },
    filterTableData(arg) {
      this.filterConditions.mdaIds = arg;
      this.getMediaAccountList();
    },
    checkIndustryModelStatus() {
      if (this.selectedRows.length == 1) {
        return false;
      }
      let firstRow = this.selectedRows[0];
      let result;
      if (firstRow.industryModel == null) {
        result = this.selectedRows.some(item => {
          return item.industryModel != null;
        });
      } else {
        result = this.selectedRows.some(item => {
          return item.industryModel == null;
        });
      }
      return result;
    },
    checkProjectStatus() {
      if (this.selectedRows.length == 1) {
        return false;
      }
      let firstRow = this.selectedRows[0];
      let result;
      if (firstRow.projectCode == null) {
        result = this.selectedRows.some(item => {
          return item.projectCode != null;
        });
      } else {
        result = this.selectedRows.some(item => {
          return item.projectCode == null;
        });
      }
      return result;
    },
    checkOperationStatus() {
      if (this.selectedRows.length == 1) {
        return false;
      }
      let firstRow = this.selectedRows[0];
      let result;
      if (firstRow.operaterId == null) {
        result = this.selectedRows.some(item => {
          return item.operaterId != null;
        });
      } else {
        result = this.selectedRows.some(item => {
          return item.operaterId == null;
        });
      }
      return result;
    },
    checkAgentStatus() {
      if (this.selectedRows.length == 1) {
        return false;
      }
      let firstRow = this.selectedRows[0];
      let result;
      if (firstRow.agentId == null) {
        result = this.selectedRows.some(item => {
          return item.agentId != null;
        });
      } else {
        result = this.selectedRows.some(item => {
          return item.agentId == null;
        });
      }
      return result;
    },
    //点击新增账户按钮
    addAccount() {
      if(this.addAccountValue == 'b') {
        this.addAccountValue = 'a';
        this.addAccountOkText = "确认";
      }
      //TT巨量引擎,GDT腾讯广告,KS磁力引擎,QC巨量千川
      if(this.activeTabKey == 'TT') {
        this.addAccountVisibleTT = true;
        // this.authorityLink =  this.ttUrl;
      } else if(this.activeTabKey == 'GDT'){
        this.addAccountVisibleGDT = true;
        this.authorityLink = this.GDTValue == 'wx' ? this.gdtWxURL : this.gdtQqURL;
      }/*else if(this.activeTabKey == 'KS'){
        this.addAccountVisibleKS = true;
        this.authorityLink = this.ksUrl;
      }*/else if(this.activeTabKey == 'QC'){
        this.addAccountVisibleQC = true;
        this.authorityLink = this.qcUrl;
      }else{
        this.$message.success('该渠道暂未开发！');
      }

    },
    //新增账户确认按钮
    addAccountOk() {
      if(this.activeTabKey == 'TT') {
        if(!this.appId) {
          message.warning("必须选择授权应用");
          return;
        }
      }
      //TT巨量引擎,GDT腾讯广告,KS磁力引擎,QC巨量千川
      if(this.addAccountValue == 'a') {
        let url;
        if(this.activeTabKey == 'TT') {
          url = this.authTTUrl;
        } else if(this.activeTabKey == 'GDT'){
          url = this.GDTValue == 'wx' ? this.gdtWxURL : this.gdtQqURL;
        }  else if(this.activeTabKey == 'KS'){
          url = this.ksUrl;
        } else{
          url = this.qcUrl;
        }
        window.open(url, '_blank')
      } else{
        navigator.clipboard.writeText(this.authorityLink);
        message.success('复制成功');
      }

    },
    appIdChange() {
      this.authTTUrl = this.appId=='1685415413328910'
        ?this.ttUrl.replaceAll('[appid]',this.appId).replace('[scope]','[2,3,4,5,8,9,130,14,112,110,120,122]')+'/&rid=kh17xvyizwm'
        :this.ttUrl.replaceAll('[appid]',this.appId).replace('[scope]','[2,3,4,5,8,9,10,11,13,14]');
      this.authorityLink = this.authTTUrl;
    },
    //监听授权方式变化
    changeAddAccount() {
      if(this.addAccountValue == 'a') {
        this.addAccountOkText = "确认";
      } else{
        this.addAccountOkText = "复制链接";
      }
    },
    //监听腾讯广告授权方式变化
    GDTValueChange() {
      this.authorityLink = this.GDTValue == 'wx' ? "https://developers.e.qq.com/oauth/authorize?client_id=1111459058&redirect_uri=https%3A%2F%2Ftias.plus%2Fextractor%2Fgdt%2FaccessToken%2Finit%2F&state=custom_params&account_type=ACCOUNT_TYPE_WECHAT"
          : "https://developers.e.qq.com/oauth/authorize?client_id=1111459058&redirect_uri=https%3A%2F%2Ftias.plus%2Fextractor%2Fgdt%2FaccessToken%2Finit%2F&state=custom_params&account_type=ACCOUNT_TYPE_QQ"
    },
    //获取低效素材列表
    getMaterialFileRebateMaterialSearch() {
      if (this.tableLoading === true) {
        return;
      }
      this.tableLoading = true;
      let params = {
        mediaChannel: this.activeTabKey,
        ...this.tablePagination,
        ...this.filterConditions,
        //素材清理
        agentId:this.filterConditions.agentId,//代理商主账户
        period: this.filterConditions.period == null ? moment(new Date()).format("YYYY-MM") : moment(this.filterConditions.period).format("YYYY-MM"),//素材清理月份
        advertiserId:this.filterConditions.advertiserId,//账户
        customerName:this.filterConditions.customerName,
      };
      if (this.filterConditions.industryModel == '') {
        delete params.industryModel;
      }

      getMaterialFileRebateMaterialSearchData(params).then(res => {
        if (res.code == 0) {
          this.clMediaTableData = res.page.list;
          this.tablePagination.current = res.page.currPage;
          this.tablePagination.pageSize = res.page.pageSize;
          this.tablePagination.total = res.page.totalCount;
        }
        console.error("===============无效素材查询=====================")
        this.tableLoading = false;
      });

    },

    //获取低效素材列表
    async getMaterialFileRebateMaterialSearchDataFromAdb() {
      this.tableLoading = true;
      if(this.tablePagination.pageSize == 0){
        this.tablePagination.pageSize = 30;
      }
      console.error("=====获取低效素材列表=======")
      let params = {
        selectType:this.filterConditions.selectType,
        mediaChannel: this.activeTabKey,
        ...this.tablePagination,
        // ...this.filterConditions,
        //素材清理
        agentId: this.filterConditions.agentId,//代理商主账户
        period: moment(this.filterConditions.period).format("YYYY-MM"),//素材清理月份
        // advertiserId: this.filterConditions.advertiserId,//账户
        advertiserIds: this.filterConditions.advertiserId,//账户IDS
        customerName: this.filterConditions.customerName,
      };
      if (this.filterConditions.industryModel == '') {
        delete params.industryModel;
      }
      try {
        await getMaterialFileRebateMaterialSearchDataUrlFromAdb(params).then(res => {
          if (res.code == 0) {
            this.clMediaTableData = res.page.list;
            this.tablePagination.current = res.page.currPage;
            this.tablePagination.pageSize = res.page.pageSize;
            this.tablePagination.total = res.page.totalCount;
          }
          this.tableLoading = false;
        });

      }catch (e) {
        this.tableLoading = false;
      }

    }
  },
  unmounted() {
    this.unmountedFlag = false;
  }
};
</script>

<style lang="less">
@import url('./index.less');
</style>
<style lang="less" scoped>
.media {
  display: flex;
  flex-direction: column;
  :deep(.filter_row) {
    padding-top: 0;
    padding-bottom: 6px;
    .ant-tabs-top-bar {
      margin-bottom: 8px;
    }
  }
  .table_wrapper {
    display: flex;
    flex-direction: column;
    .table_operation {
      margin-bottom: 10px;
    }
    :deep(.media_account_table) {
      flex-grow: 1;
      .ant-table-scroll .ant-table-body {
        max-height: calc(100vh - 390px) !important;
      }
      .ant-table-pagination {
        margin-bottom: 0 !important;
        margin-top: 12px;
      }
    }
  }
}
</style>