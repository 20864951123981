<template>
  <a-modal v-model:visible="modal.visible" :title="modal.title" :width="1000">
    <div class="total_detail_wrapper w100">
      <a-table :columns="columns" :data-source="tableData" :pagination="false" :scroll="{ y: 400 }"> </a-table>
    </div>
    <template #footer>
      <a-button @click="modal.visible = false">关闭</a-button>
    </template>
  </a-modal>
</template>

<script>
export default {
  name: 'TotalDetail',
  components: {},
  props: {
    // 点击行数据
    rowData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    const columns = [
      { title: '当前账户总数', dataIndex: '1st', width: 120 },
      { title: '当前总开户主体数', dataIndex: '2st', width: 150 },
      { title: '自运营账户', dataIndex: '3st', width: 110 },
      { title: '代运营账户', dataIndex: '4st', width: 110 },
      { title: '二级代理账户', dataIndex: '5st', width: 120 },
      { title: '有消耗账户数', dataIndex: '6st', width: 120 },
      { title: '有消耗账户数当前余额', dataIndex: '7st', width: 180 },
    ];
    return {
      columns,
      tableData: [],
      modal: {
        visible: false,
        title: '合计-详情',
      },
    };
  },
  async created() {
    await this.getTableData();
  },
  methods: {
    // 获取表格数据
    async getTableData() {
      const res = { data: [] };
      for (let index = 0; index < 50; index++) {
        this.tableData.push({
          '1st': '1st',
          '2st': '2st',
          '3st': '3st',
          '4st': '4st',
          '5st': '5st',
          '6st': '6st',
          '7st': '7st',
          key: this.$UUID.v1(),
        });
      }
    },
  },
};
</script>

<style scoped lang="less">
</style>
