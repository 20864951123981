<template>
  <a-modal v-model:visible="visible" title="编辑媒体账户" :width="800" wrap-class-name="edit_medai_account_modal" @cancel="clearData">
    <a-radio-group v-model:value="radioValue" @change="radioChange">
      <a-radio-button value="transfer">转移历史消耗</a-radio-button>
      <a-radio-button value="shift">换绑账户</a-radio-button>
    </a-radio-group>
    <a-form :label-col="{ span: 4 }" v-if="radioValue === 'transfer'">
      <a-form-item label="历史消耗时段" required="">
        <a-range-picker value-format="YYYY-MM-DD" style="width: 300px" v-model:value="historyDate"></a-range-picker>
      </a-form-item>
      <!-- 转移历史消耗的【接收历史消耗运营】仅能选择一个人，且转移与接收运营不能为同一个人。---需要禁用 -->
      <a-form-item label="接受历史消耗运营" required>
        <a-select allow-clea style="width: 300px" v-model:value="transferHistory.operaterId">
          <a-select-option :disabled="userIdList.includes(item.userId + '')" v-for="item in userList" :value="item.userId" :label="item.userCode" :key="item.id" @click="transferHistory.operaterName = item.userCode">{{ item.userCode }}</a-select-option>
        </a-select>
      </a-form-item>
    </a-form>
    <a-form :label-col="{ span: 4 }" v-else>
      <a-form-item label="绑定运营" required>
        <a-select allow-clea style="width: 300px" v-model:value="transferHistory.operaterId">
          <a-select-option :disabled="userIdList.includes(item.userId + '')" v-for="item in userList" :value="item.userId" :label="item.userCode" :key="item.id" @click="transferHistory.operaterName = item.userCode">{{ item.userCode }}</a-select-option>
        </a-select>
      </a-form-item>
    </a-form>
    <template #footer>
      <a-button @click="clearData">取消</a-button>
      <a-button type="primary" :loading="btnLoading" @click="handleOk">确定</a-button>
    </template>
  </a-modal>
</template>

<script>
import { message, Modal } from 'ant-design-vue';
import { editMediaAccount } from '@/api/mediaAccount/mediaAccount.js';
export default {
  name: 'EditMediaAccountModal',
  components: {},
  props: {
    // 点击行数据
    accountData: {
      type: Object,
      default: () => {},
    },
    // 点击行数据
    userList: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['afterSave'],
  data() {
    return {
      visible: false,
      radioValue: 'transfer',
      historyDate: [],
      transferHistory: {
        mdaccountIdList: [], //账户id列表
        operaterId: '',
        operaterName: '',
      },
      btnLoading: false,
    };
  },
  computed: {
    userIdList() {
      return this.accountData.map(item => item.operaterId);
    },
  },
  methods: {
    // 保存
    handleOk() {
      const mydata = { ...this.transferHistory };
      mydata.mdaccountIdList = this.accountData.map(item => item.advertiserId);
      // 转移历史消耗
      if (this.radioValue === 'transfer') {
        if (!this.historyDate.length) {
          message.warning('请选择历史消耗时段！');
          return;
        }
        mydata.startTime = this.historyDate[0];
        mydata.endTime = this.historyDate[1];
      }
      if (!this.transferHistory.operaterName) {
        message.warning('请选择运营！');
        return;
      }

      const content = {
        transfer: '确认后该媒体账户在所选时间段内的历史消耗将全部转移至所选运营。请谨慎操作！！！',
        shift: '确认后该媒体账户今后所有的消耗将全部绑定至所选运营。请谨慎操作! ! !',
      };
      const _this = this;
      Modal.confirm({
        title: '请确认',
        okText: '确定',
        cancelText: '取消',
        content: content[_this.radioValue],
        async onOk() {
          _this.btnLoading = true;
          const res = await editMediaAccount(mydata);
          if (res.code === 0) {
            _this.$emit('afterSave', { type: 'editAccount' });
            setTimeout(() => {
              message.success('编辑成功');
              _this.btnLoading = false;
              _this.visible = false;
              _this.clearData();
            }, 1000);
          } else {
            _this.btnLoading = false;
          }
        },
        onCancel() {},
      });
    },
    // 用来清空数据，避免下次打开时有残留数据
    clearData() {
      this.$clearObj(this.transferHistory);
      this.historyDate = [];
      this.radioValue = 'transfer';
      this.visible = false;
    },
    radioChange() {
      this.$clearObj(this.transferHistory);
      this.historyDate = [];
    },
  },
};
</script>

<style lang="less">
.edit_medai_account_modal {
  .ant-form {
    margin: 10px 0;
    height: 200px;
  }
}
</style>
