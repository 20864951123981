<template>
  <a-modal v-model:visible="visible" :width="1100" wrap-class-name="account_authorize_modal">
    <div class="account_authorize_wrapper w100">
      <!-- 左侧账号信息等 -->
      <div class="left module h100">
        <div class="logo module_header w100">
          <div class="img_wrapper w100">
            <img src="@/assets/image/logo.jpg" alt="logo" />
          </div>
          <p class="account_info">
            <span> 当前账号:上海芦鸣网络科技有限公司-KA(92100587150) </span>
            <a href="#" class="float-r"><SwapOutlined />切换账号</a>
          </p>
        </div>
        <a-tabs v-model:activeKey="activeTabKey" class="module_body w100">
          <a-tab-pane key="1" tab="基于商业账户授权">
            <div class="account_authorize w100 h100">
              <a-input-search placeholder="请输入你想搜索的账户ID或名称" @search.enter="onSearch"></a-input-search>
              <a-row type="flex" justify="space-between" style="margin-top: 10px">
                <a-checkbox @change="selectAllChange" v-model:checked="selectAll">全选</a-checkbox>
                <span> 已选{{ selectedList.length }}/{{ accountList.length }} </span>
              </a-row>
              <a-divider style="margin: 5px 0"></a-divider>
              <a-row class="account_list">
                <a-checkbox-group v-model:value="selectedList" style="width: 100%">
                  <a-col :span="24" class="account_item" v-for="account in copyAccountList" :key="account.key">
                    <a-checkbox :value="account.name" @change="selectChange">
                      <span class="ellipsis" :title="account.name">{{ account.name }} </span>
                    </a-checkbox>
                    <span class="tags">
                      <a-tag class="badge">{{ account.num }}</a-tag>
                      <a-tag color="blue">{{ account.type }}</a-tag>
                    </span>
                  </a-col>
                </a-checkbox-group>
              </a-row>
            </div>
          </a-tab-pane>
          <a-tab-pane key="2">
            <template #tab>
              基于用户授权
              <a-tooltip>
                <template #title>
                  <span> 这是提示内容</span>
                </template>
                <QuestionCircleOutlined />
              </a-tooltip>
            </template>
            暂无
          </a-tab-pane>
        </a-tabs>
      </div>
      <a-divider type="vertical" class="h100" style="margin: 0 20px"></a-divider>
      <!-- 右侧权限信息 -->
      <div class="right module h100">
        <div class="module_header w100">
          <div class="img_wrapper app w100">
            <img src="@/assets/image/logo.jpg" alt="logo" />
            <div class="app_info">
              <p>TITAS</p>
              <p class="origin">提供方：上海芦鸣网络科技有限公司</p>
            </div>
          </div>
          <p class="perssion_notice">申请权限：将允许应用做以下操作，授权后账号所产生的任何纠纷责任和后属均由您和应用供应商平担。与巨量引擎无关，(授权后可随时更改授权内容)</p>
        </div>
        <ul class="module_body permission_list w100">
          <li v-for="permission in permissionList" :key="permission" class="permission_item">{{ permission }}</li>
        </ul>
      </div>
    </div>
    <!-- 底部提示 -->
    <p class="notice" style="color: #999; margin-top: 10px">提示:每次授权将基于是所与法政号化权限覆生历史授权.可点此进入<a href="#">应用管理</a>界面</p>
    <template #footer>
      <div class="modal_footer">
        <p>授权后即表明你已同意 <a href="#">《巨量引擎商业平台服务协议》</a></p>
        <a-button :disabled="disabled" @click="authorize" type="primary">同意授权</a-button>
      </div>
    </template>
  </a-modal>
</template>

<script>
import { message } from 'ant-design-vue';
import { QuestionCircleOutlined, SwapOutlined } from '@ant-design/icons-vue';

export default {
  name: 'AccountAuthorize',
  components: { QuestionCircleOutlined, SwapOutlined },
  props: {},
  data() {
    return {
      visible: false,
      disabled: true,
      activeTabKey: '1',
      selectAll: false,
      selectedList: ['3上海芦海网络科技有限公司华东(110761960254)', '5上海芦海网络科技有限公司华东(110761960254)'],
      copyAccountList: [], //用于展示的账号列表
      accountList: [], //账号列表
      permissionList: [], //权限列表
    };
  },
  async mounted() {
    await this.getAccountList();
    await this.getPermissionList();
  },
  methods: {
    //  获取媒体账号列表
    async getAccountList() {
      for (let index = 0; index < 30; index++) {
        let num = index * 5 > 99 ? '99+' : index * 5;
        this.accountList.push({ name: `${index}上海芦海网络科技有限公司华东(110761960254)`, num: num, type: '广告代理商', key: this.$UUID.v1() });
      }
      this.copyAccountList = [...this.accountList];
    },
    // 获取权限列表
    async getPermissionList() {
      this.permissionList = ['查询资金与流水', 'DMP人群管理', '数揭报表', '工具', '青鸟线索通管理', '万花間创意能力', '曾家(客户中心账号)管理', '广告投放', '重询C理商下广告主列表'];
    },
    // 搜索账户
    onSearch(val) {
      this.copyAccountList = this.accountList.filter(item => item.name.indexOf(val) > -1);
    },
    // 全选改变时
    selectAllChange() {
      this.selectedList = [];
      if (this.selectAll) {
        this.accountList.forEach(item => {
          this.selectedList.push(item.name);
        });
      }
    },
    // 选中改变时
    selectChange() {
      setTimeout(() => {
        this.selectAll = this.selectedList.length === this.accountList.length;
      }, 0);
    },
    //  授权
    authorize() {
      message.success('授权成功');
    },
  },
};
</script>

<style  lang="less">
@import url('./AccountAuthorize.less');
</style>
