<template>
  <a-modal style="top: 160px" :title="`${mode.label}`" :width="484" v-model:visible="visible" wrap-class-name="add_agent" @ok="visible = false" :after-close="afterClose">
    <div class="w100 h100 add_agent_wrapper">
      <div class="w100 agent_wrapper">
        <a-row style="background: #f5f7fa; line-height: 36px">
          <a-col :span="24">
            <p>
              <span>{{ mode.label }}列表</span>
              <PlusCircleOutlined @click="addItem" class="blue-icon pointer" />
            </p>
          </a-col>
        </a-row>
        <div class="agent_list">
          <a-row v-for="(agent, index) in agentList" :key="agent">
            <a-col :span="20">
              <div title="双击编辑" :contenteditable="agent.contenteditable" class="edit_name" @dblclick="dblclickAgent($event)" @blur="onBlur(agent, $event)">
                {{ agent.agentName }}
              </div>
            </a-col>
            <a-col :span="3">
              <DeleteOutlined @click="deleteAgnet(agent, index)" class="pointer float-r" />
            </a-col>
          </a-row>
        </div>
      </div>
    </div>
  </a-modal>
</template>

<script>
import { addAgent, updateAgent, deleteAgent } from '@/api/mediaAccount/mediaAccount.js';
import { Modal } from 'ant-design-vue';
import { DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons-vue';
export default {
  name: 'AddAgentModal',
  components: { DeleteOutlined, PlusCircleOutlined },
  props: {
    mode: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  emits: ['afterClose', 'refreshList'],
  inject: ['getAgentList', 'getFacilitatorList', '$bus'],
  data() {
    return {
      visible: false,
      agentName: '',
      agentList: [],
    };
  },
  watch: {
    originList: {
      handler(val) {
        this.getList(val);
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    originList() {
      let list = [];
      if (this.mode.value == 'Self') {
        list = this.getAgentList();
      } else if (this.mode.value == 'Service') {
        list = this.getFacilitatorList();
      }
      return list;
    },
  },
  methods: {
    // 获取二代和服务商列表
    getList(list) {
      const agentList = [];
      list.forEach(item => {
        if (this.mode.value == 'Self') {
          agentList.push({
            agentName: item.agentName,
            id: item.id,
          });
        } else if (this.mode.value == 'Service') {
          agentList.push({
            agentName: item.facilitatorName,
            id: item.facilitatorId,
          });
        }
      });
      this.agentList = agentList;
    },
    // 新增服务商
    async addAgent(agentName) {
      const res = await addAgent({ industryModel: this.mode.value, agentName: agentName });
      if (res.code === 0) {
        this.$bus.emit('refreshAgentList', this.mode.value);
      }
    },
    // 删除服务商
    deleteAgnet(record, index) {
      const _this = this;
      Modal.confirm({
        title: '删除',
        content: '该信息删除后将不予保留，请谨慎操作！',
        async onOk() {
          const res = await deleteAgent([record.id]);
          if (res.code === 0) {
            _this.$message.info('已删除');
            _this.$bus.emit('refreshAgentList', _this.mode.value);
          } else {
            _this.$message.success('删除失败，请重试！');
          }
        },
        onCancel() {},
      });
    },
    // 修改服务商
    async updateAgent(record, e) {
      e.target.setAttribute('contenteditable', false);
      const agentName = e.target.innerText;
      const data = { industryModel: this.mode.value, ...record };
      data.agentName = agentName;
      const res = await updateAgent(data);
      if (res.code === 0) {
        record.agentName = agentName;
        this.$bus.emit('refreshAgentList', this.mode.value);
      } else {
        this.$message.success('添加失败，请重试！');
        e.target.innerText = record.agentName;
      }
    },
    afterClose() {
      this.$emit('afterClose');
    },
    // 点击加号
    addItem() {
      this.agentList.unshift({ contenteditable: true, agentName: '' });
      this.$nextTick(() => {
        const e = document.querySelector('.edit_name');
        e.focus();
      });
    },
    // 双击服务商名字
    dblclickAgent(e) {
      e.target.setAttribute('contenteditable', true);
      e.target.focus();
    },
    // 服务商名字编辑框失去焦点
    onBlur(agent, e) {
      let agentName = e.target.innerText;
      if (agent.id) {
        this.updateAgent(agent, e);
        agent.contenteditable = false;
      } else if (agentName) {
        this.addAgent(agentName);
      } else {
        this.agentList.splice(0, 1);
      }
    },
  },
};
</script>

<style scoped lang="less">
.agent_wrapper {
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  .agent_list {
    height: 300px;
    overflow: auto;
  }
  .ant-row {
    text-indent: 10px;
    padding-left: 10px;
    height: 36px;
    &:hover {
      background: #e9eff7;
    }
    .anticon-delete {
      display: none;
      line-height: 36px;
    }
    &:hover {
      .anticon-delete {
        display: block;
      }
    }
  }
  .edit_name {
    line-height: 28px;
    width: 144px;
  }
  .edit_name[contenteditable='true'] {
    outline: 1px solid #999;
    height: 28px;
    background-color: #fff;
    margin-top: 4px;
  }
}
</style>
<style lang="less">
.add_agent {
  .ant-form-item {
    margin-bottom: 10px;
  }
  .ant-modal-body {
    padding: 12px;
  }
}
</style>
