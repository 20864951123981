<template>
  <a-modal v-model:visible="visible" :title="modalTitle" :width="800" :after-close="afterClose">
    <div class="assign_form_wrapper">
      <a-form v-hasPermission="'MEDIA_BINDINDUSTRY'" :wrapper-col="wrapperCol" class="w100" :label-col="labelCol" :colon="false">
        <a-row>
          <a-col :span="12">
            <a-form-item label="业务模式">
              <PlusCircleOutlined class="blue-icon" @click="addRow('industryModelList')" />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row style="display: flex" v-for="(busimode, index) in industryModelList" :key="busimode" class="project_item">
          <a-col :span="20" :offset="3">
            <a-row>
              <a-col :span="12">
                <a-radio-group v-model:value="busimode.industryModel" button-style="solid" @change="onIndustryModelChange($event, index)">
                  <a-radio-button v-for="industry in industryModelOptions" :key="industry.value" :value="industry.value">{{ industry.label }}</a-radio-button>
                </a-radio-group>
              </a-col>
              <a-col :span="10">
                <span> 有效起始日期： </span>
                <a-date-picker class="blue-icon" style="width: 50%" :disabled-date="val => disabledDate(val, index, 'industryModelList')" v-model:value="busimode.startTime" :allow-clear="false" value-format="YYYY-MM-DD HH:mm:ss" />
              </a-col>
              <a-col :span="1" v-show="industryModelList.length > 1" class="delete_row" @click="deleteRow('industryModelList', index)"> 删除 </a-col>
            </a-row>
          </a-col>
        </a-row>
      </a-form>
      <a-form v-if="accountDataIndustryModel !== 'Self'" :wrapper-col="wrapperCol" class="w100" :label-col="labelCol" :colon="false">
        <a-row>
          <a-col :span="12">
            <a-form-item label="所属产品">
              <PlusCircleOutlined class="blue-icon" @click="addRow('projectList')" />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row v-for="(project, index) in projectList" :key="project" class="project_item">
          <a-col :span="20" :offset="3">
            <a-row>
              <a-col :span="12">
                <a-select v-bind="$filterOptions" v-model:value="project.projectCode" placeholder="请选择产品" style="width: 95%">
                  <a-select-option @click="project.projectName = product.projectName" :value="product.projectCode" :label="product.projectName" v-for="product in productList" :key="product.id">{{ product.projectName }}</a-select-option>
                </a-select>
              </a-col>
              <a-col :span="10">
                <span> 有效起始日期： </span>
                <a-date-picker class="blue-icon" style="width: 50%" :disabled-date="val => disabledDate(val, index, 'projectList')" :allow-clear="false" v-model:value="project.startTime" value-format="YYYY-MM-DD  HH:mm:ss" />
              </a-col>
              <a-col :span="1" v-show="projectList.length > 1" class="delete_row" @click="deleteRow('projectList', index)"> 删除 </a-col>
            </a-row>
          </a-col>
        </a-row>
      </a-form>
      <a-form :wrapper-col="wrapperCol" class="w100" :label-col="labelCol" :colon="false">
        <a-row v-if=" accountDataIndustryModel != 'CusSelfOpera' ">
          <a-col :span="24">
            <a-form-item :label="distributeType" :label-col="{ span: 3 }">
              <PlusCircleOutlined class="blue-icon" @click="addRow('operaterList', accountDataIndustryModel)" />
              <a-tag v-if="accountDataIndustryModel != 'Substitute'" @click="addService" color="#197AFB" class="float-r" style="transform: translate(-26px, 10px)">添加{{ addAgentMode.label }}</a-tag>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row v-for="(operator, index) in operatorOrServiceList" :key="operator" class="operator_item">
          <a-col :span="20" :offset="3">
            <a-row>
              <a-col :span="12" v-if="accountDataIndustryModel === 'Service'">
                <a-select style="width: 95%" placeholder="请选择服务商" v-model:value="operator.facilitatorId" v-bind="$filterOptions">
                  <a-select-option @click="operator.facilitatorName = person.facilitatorName" :label="person.facilitatorName" :value="person.facilitatorId" v-for="person in ServiceList" :key="person.facilitatorId">{{ person.facilitatorName }}</a-select-option>
                </a-select>
              </a-col>
              <a-col :span="12" v-else-if="accountDataIndustryModel === 'Substitute'">
                <a-select v-bind="$filterOptions" placeholder="请选择运营" style="width: 95%" v-model:value="operator.operatorId">
                  <a-select-option @click="operator.operatorName = person.username" :value="person.userId" v-for="person in personList" :key="person.userId" :label="person.username">{{ person.username }}</a-select-option>
                </a-select>
              </a-col>
              <a-col :span="12" v-else>
                <a-select v-bind="$filterOptions" placeholder="请选择二代" style="width: 95%" v-model:value="operator.agentId">
                  <a-select-option @click="operator.agentName = person.agentName" :value="person.id" v-for="person in SelfList" :key="person.id" :label="person.agentName">{{ person.agentName }}</a-select-option>
                </a-select>
              </a-col>
              <a-col :span="10">
                <span> 有效起始日期： </span>
                <a-date-picker class="blue-icon" style="width: 50%" :disabled-date="val => disabledDate(val, index, 'operaterList')" :allow-clear="false" v-model:value="operator.startTime" value-format="YYYY-MM-DD  HH:mm:ss" />
              </a-col>
              <a-col v-show="operatorOrServiceList.length > 1" :span="1" class="delete_row" @click="deleteRow('operaterList', undefined, operator)"> 删除 </a-col>
            </a-row>
          </a-col>
        </a-row>
        <a-row v-show="showaddService">
          <a-col :span="21" :offset="3">
            <a style="color: rgb(191, 191, 191)" @click="addService">>> 无{{ addAgentMode.label }}信息，添加</a>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <template #footer>
      <a-button @click="visible = false">取消</a-button>
      <a-button v-hasPermission="'MEDIA_ADDBINDPRODUCTS'" @click="handleOk" type="primary" :loading="btnLoading">确定</a-button>
    </template>
  </a-modal>
  <component :is="componentName" :mode="addAgentMode" ref="addAgentModal" v-if="componentName"></component>
</template>

<script>
import AddAgentModal from './AddAgentModal.vue';
import { PlusCircleOutlined } from '@ant-design/icons-vue';
import { assignAccount, getBindHistory } from '@/api/mediaAccount/mediaAccount.js';
import moment from 'moment';

export default {
  name: 'AssignOneAccount',
  components: { AddAgentModal, PlusCircleOutlined },
  emits: ['afterSave'],
  props: {
    accountData: { type: Object, default: () => {} }, //需要分配的账号
    personList: { type: Array, default: () => [] }, //运营人员列表
    productList: { type: Array, default: () => [] }, //产品人员列表
  },
  inject: ['getAgentList', 'getFacilitatorList'],
  watch: {
    visible(val) {
      if (val) {
        this.getHistory();
      }
    },
  },
  data() {
    const industryModelOptions = [
      { value: 'Self', label: '二代' },
      { value: 'Substitute', label: '代运营' },
      { value: 'Service', label: '服务商' },
      { value: 'CusSelfOpera', label: '客户自运营' },
    ];
    const busiModeListItem = {
      industryModel: undefined,
      startTime: moment().format('YYYY-MM-DD'),
      endTime: undefined,
    };
    const projectListItem = {
      projectCode: undefined,
      projectName: undefined,
      startTime: moment().format('YYYY-MM-DD'),
      endTime: undefined,
    };
    const operaterListItem = {
      facilitatorId: undefined,
      agentName: undefined,
      operatorId: undefined,
      operatorName: undefined,
      startTime: moment().format('YYYY-MM-DD'),
      endTime: undefined,
    };

    return {
      projectListItem,
      operaterListItem,
      projectList: [],
      operaterList: [],
      industryModelList: [],
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
      visible: false,
      btnLoading: false,
      componentName: '',
      industryModelOptions,
      busiModeListItem,
      getBindHistory: {},
    };
  },
  computed: {
    addAgentMode() {
      const dict = { Service: '服务商', Self: '二代' };
      let value = this.accountDataIndustryModel;
      let label = dict[this.accountDataIndustryModel];
      return { value, label };
    },
    ServiceList() {
      return this.getFacilitatorList();
    },
    SelfList() {
      return this.getAgentList();
    },
    modalTitle() {
      // return `${this.accountData.advertiserId}(${this.accountData.advertiserName})`;
      return '媒体账号分配'; //UI图上给的这样，原本title为上一行的内容
    },
    showaddService() {
      return this.operaterList.slice(-1)[0]?.industryModel === 'Level';
    },
    accountDataIndustryModel() {
      if (this.industryModelList.length == 0) {
        return '';
      }
      return this.industryModelList[this.industryModelList.length - 1].industryModel;
    },
    distributeType() {
      if (this.accountDataIndustryModel == 'Service') {
        return '所属服务商';
      }
      if (this.accountDataIndustryModel == 'Substitute') {
        return '所属运营';
      }
      if (this.accountDataIndustryModel == 'Self') {
        return '所属二代';
      }
      if (this.accountDataIndustryModel == 'CusSelfOpera') {
        return null;
      }
      return '所属人员';
    },
    operatorOrServiceList() {
      return this.operaterList.filter(item => {
        return (this.accountDataIndustryModel == 'Substitute' && item.operatorId != null) || (this.accountDataIndustryModel == 'Service' && item.facilitatorId != null) || (this.accountDataIndustryModel == 'Self' && item.agentId != null);
      });
    },
  },
  methods: {
    // 点击保存
    async handleOk() {
      let projectArr = this.projectList.map(item => item.projectCode + '' + item.startTime);
      let operatorArr = this.operaterList.map(item => item.operatorId + '' + item.startTime || item.facilitatorId + '' + item.startTime || item.agentId + '' + item.startTime);

      if (projectArr.length != [...new Set(projectArr)].length) {
        this.$message.info('存在重复产品，请重新选择！');
        return;
      }
      if (operatorArr.length != [...new Set(operatorArr)].length) {
        this.$message.info('存在重复运营模式的运营，请重新选择！');
        return;
      }

      if (this.operatorOrServiceList.some(item => !item.operatorId && !item.facilitatorId && !item.agentId)) {
        let msg = '';
        if (this.accountDataIndustryModel == 'Service') {
          msg = '请选择服务商';
        }
        if (this.accountDataIndustryModel == 'Substitute') {
          msg = '请选择运营人员';
        }
        if (this.accountDataIndustryModel == 'Self') {
          msg = '请选择运营人员';
        }
        this.$message.info(msg);
        return;
      }
      if (!this.industryModelList.slice(-1)[0].startTime || !this.projectList.slice(-1)[0].startTime || (this.operatorOrServiceList.length && !this.operatorOrServiceList.slice(-1)[0].startTime)) {
        this.$message.info('有效起始时间不能为空！！');
        return;
      }

      let industryModelCount = this.industryModelList.length;
      if (industryModelCount > 1 && this.industryModelList[industryModelCount - 1].industryModel == this.industryModelList[industryModelCount - 2].industryModel) {
        this.$message.error('请不要重复选择运营模式！');
        return;
      }

      this.btnLoading = true;
      const data = {
        projects: [...this.projectList],
        operaters: [...this.operaterList.filter(item => item.operatorId || item.facilitatorId || item.agentId)],
        industryModels: [...this.industryModelList],
        mdaccountId: this.accountData.advertiserId,
        type: 1,
      };
      data.industryModels.forEach(item => {
        if (!item.startTime) {
          item.startTime = '2021-07-01 00:00:00';
        }
      });
      const res = await assignAccount(data);
      if (res.code === 0) {
        this.$emit('afterSave');
        setTimeout(() => {
          this.visible = false;
        }, 500);
      }
      this.btnLoading = false;
    },
    // 添加一行
    addRow(type, personType) {
      const item = { ...this[`${type}Item`] };
      item.startTime = null;
      if (type == 'operaterList') {
        if (!this.accountDataIndustryModel) {
          this.$message.info('请先选择业务模式');
          return;
        }
        // 检测每条记录都有时间，这样检测如果最后一条记录有开始时间则会堵塞在这
        // if (this[type].length && !this[type].slice(-1)[0].startTime) {
        //   this.$message.info('请为每条记录选择时间！');
        //   return;
        // }
        if (this[type].length && !this[type].every(item => item.startTime)) {
          this.$message.info('请为每条记录选择时间！');
          return;
        }

        if (personType) {
          if (personType == 'Substitute') {
            item.operatorId = '';
          } else if (personType == 'Service') {
            item.facilitatorId = '';
          } else {
            item.agentId = '';
          }
        }
        this[type].push(item);
      } else if (this[type].slice(-1)?.[0]?.startTime) {
        this[type].push(item);
      } else {
        this.$message.info('请为每条记录选择时间！');
      }
    },
    // 删除一行
    deleteRow(type, index, item) {
      if (isNaN(index) && item) {
        let i = this[type].findIndex(val => item.startTime == val.startTime);
        this[type].splice(i, 1);
      } else {
        if (this[type].length > 1) {
          this[type].splice(index, 1);
        }
      }
    },
    // 点击添加服务商，打开弹窗
    addService() {
      this.componentName = 'add-agent-modal';
      this.$nextTick(() => {
        this.$refs.addAgentModal.visible = true;
      });
    },
    // 点击添加二代，打开弹窗
    addSelf() {
      this.componentName = 'add-agent-modal';
      this.$nextTick(() => {
        this.$refs.addAgentModal.visible = true;
      });
    },
    // 最近一条记录时间必须迟于上一条记录时间
    disabledDate(currentDate, index, type) {
      // 最后一条
      if (index && this[type].length === index + 1) {
        let lastDate = this[type][index - 1].startTime;
        return moment(lastDate) >= moment(currentDate).subtract(1, 'days');
      } else if (!index) {
        let lastDate = this[type][index + 1] ? this[type][index + 1].startTime : moment();
        return moment(lastDate) <= moment(currentDate);
      } else {
        let lastDate = this[type][index + 1].startTime;
        let nextDate = this[type][index - 1].startTime;
        return moment(lastDate) <= moment(currentDate) || moment(nextDate) >= moment(currentDate);
      }
    },
    // 关闭分配弹窗
    afterClose() {
      this.projectList = [{ ...this.projectListItem }];
      this.operaterList = [{ ...this.operaterListItem }];
    },
    // 获取历史记录
    async getHistory() {
      const res = await getBindHistory(this.accountData);
      this.projectList = res.list.project.length ? res.list.project : [{ startTime: '2021-07-01' }];
      this.industryModelList = res.list.industry.length ? res.list.industry : [{ startTime: '2021-07-01' }];
      this.operaterList = res.list.operater;
      // if (res.list.operater.length) {
      //   this.operaterList = res.list.operater.map(item => {
      //     if (item.industryModel === 'Service') {
      //       item.facilitatorId = item.operatorId;
      //       item.agentName = item.operatorName;
      //       item.operatorId = undefined;
      //       item.operatorName = undefined;
      //     }
      //     return item;
      //   });
      // } else {
      //   this.operaterList = [{ ...this.operaterListItem }];
      // }
    },
    onIndustryModelChange(e, index) {
      this.operaterList.splice(0, 1000);
      if (index == 0) {
        return;
      }

      if (e.target.value == this.industryModelList[index - 1].industryModel) {
        this.$message.warning('请选择与上一条记录不同的业务模式！');
      }
    },
  },
};
</script>

<style scoped lang="less">
.assign_form_wrapper {
  width: 100%;
  overflow: auto;
  :deep(.ant-form) {
    .ant-form-item-label {
      line-height: 40px !important;
    }
    min-width: 700px;
    & > .ant-row {
      margin-bottom: 10px;
    }
  }
  .delete_row {
    cursor: pointer;
    line-height: 32px;
    &:first-child {
      display: none;
    }
  }
  .add_agent {
    &:nth-child(2) {
      display: none;
    }
  }
  .ant-form-item {
    margin-bottom: 0;
  }
  :deep(.ant-calendar-picker-input) {
    height: 32px;
  }
}
</style>
