<template>
  <a-modal v-model:visible="visible" ok-text="确定" cancel-text="取消" :title="title" :width="800" wrap-class-name="row_detail_modal">
    <div class="row_detail_wrapper w100">
      <a-tabs v-model:activeKey="activeTab">
        <a-tab-pane key="1" tab="基本信息" class="base_info">
          <a-form :label-col="{ span: 4 }" :wrapper-col="{ span: 10 }" label-align="left">
            <a-form-item v-for="item in baseInfo" :key="item.label" :label="item.label">
              <a-input :value="item.value" />
            </a-form-item>
          </a-form>
        </a-tab-pane>
        <a-tab-pane key="2" tab="效果数据" class="effect_data">
          <!-- 筛选条件表单 -->
          <a-form :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }" label-align="left" class="data_filter">
            <a-form-item label="日期范围">
              <a-space>
                <a-range-picker style="width: 200px" v-model:value="effectForm.dateRang1"></a-range-picker>
                <a-checkbox :checked="effectForm.compare"> 对比</a-checkbox>
                <a-range-picker style="width: 200px" v-model:value="effectForm.dateRang1"></a-range-picker>
              </a-space>
            </a-form-item>
            <a-form-item label="时间维度">
              <a-radio-group v-model:value="effectForm.time">
                <a-radio-button value="day">按天</a-radio-button>
                <a-radio-button value="hour">按小时</a-radio-button>
              </a-radio-group>
            </a-form-item>
            <a-form-item label="指标口径">
              <a-radio-group v-model:value="effectForm.index">
                <a-radio-button value="ad">广告播放</a-radio-button>
                <a-radio-button value="transform">转化回传</a-radio-button>
              </a-radio-group>
            </a-form-item>
            <a-form-item label="数据细分">
              <a-select v-model:value="effectForm.data" style="width: 200px"> </a-select>
            </a-form-item>
          </a-form>
          <!-- chart图 -->
          <div class="data_trend">
            <p class="module_title">数据趋势</p>
            <div id="chart_wrapper">
              <h1>chart容器</h1>
            </div>
          </div>
          <!-- 表格 -->
          <div class="data_report">
            <div class="module_header">
              <span class="module_title"> 数据报表 </span>
              <a-space class="mr10">
                <a-checkbox>查看汇总数据 </a-checkbox>
                <span class="pointer"> <DownloadOutlined />下载报表 </span>
                <span class="pointer"> <ProfileOutlined />自定义列 </span>
              </a-space>
            </div>
            <a-table :columns="columns" :data-source="tableData" :pagination="false" :scroll="{ x: 1200, y: 300 }"> </a-table>
          </div>
        </a-tab-pane>
        <a-tab-pane key="3" tab="操作日志" class="operation_log">操作日志</a-tab-pane>
      </a-tabs>
    </div>
    <template #footer>
      <a-button @click="visible = false">关闭</a-button>
    </template>
  </a-modal>
</template>

<script>
import { DownloadOutlined, ProfileOutlined } from '@ant-design/icons-vue';

export default {
  name: 'RowDetail',
  components: { DownloadOutlined, ProfileOutlined },
  props: {
    // 点击行数据
    rowData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    // 基本信息
    const baseInfo = [
      { label: '账户ID', value: '1111' },
      { label: '账户名称', value: '1111' },
      { label: '开户主体', value: '1111' },
      { label: '所属人员', value: '1111' },
      { label: '消耗', value: '1111' },
      { label: '当前余额', value: '1111' },
      { label: '首次消耗时间', value: '1111' },
    ];
    // 报表数据表头
    const columns = [
      { title: '媒体账户ID', dataIndex: '1st' },
      { title: '日期（对比）', dataIndex: '2st' },
      { title: '花费', dataIndex: '3st', width: 80 },
      { title: '曝光量', dataIndex: '4st', width: 80 },
      { title: '点击量', dataIndex: '5st', width: 80 },
      { title: '千次展现均价', dataIndex: '6st' },
      { title: '点击率', dataIndex: '7st', width: 80 },
      { title: '点击均价', dataIndex: '8st' },
      { title: 'APP激活总量', dataIndex: '9st' },
      { title: 'APP激活成本', dataIndex: '10st' },
      { title: '首次付费行为', dataIndex: '11st' },
    ];
    return {
      visible: false,
      activeTab: '1',
      baseInfo,
      //效果数据表单
      effectForm: {
        dateRange1: [],
        dateRange2: [],
        compare: '',
        time: 'day',
        index: 'ad',
        data: '',
      },
      columns,
      tableData: [],
    };
  },
  computed: {
    title() {
      return `账号${this.rowData.advertiserId}-详情信息`;
    },
  },
  async created() {
    await this.getTableData();
  },
  methods: {
    // 获取表格数据
    async getTableData() {
      const res = { data: [] };
      for (let index = 0; index < 50; index++) {
        this.tableData.push({
          '1st': '1st',
          '2st': '2st',
          '3st': '3st',
          '4st': '4st',
          '5st': '5st',
          '6st': '6st',
          '7st': '7st',
          '8st': '8st',
          '9st': '9st',
          '10st': '10st',
          '11st': '11st',
          key: this.$UUID.v1(),
        });
      }
    },
  },
};
</script>

<style scoped lang="less">
.row_detail_wrapper {
  height: 20rem;
}
</style>
<style lang="less">
.row_detail_modal {
  .ant-modal-body {
    padding: 0 20px;
    height: 100%;

    .ant-form-item {
      margin-bottom: 10px;
    }
    .effect_data {
      height: calc(20rem - 70px);
      padding-right: 16px;
      box-sizing: border-box;
      overflow: auto;
      .module_title {
        font-size: 16px;
        line-height: 40px;
        font-weight: bold;
      }
      .data_filter {
        .ant-radio-button-wrapper {
          width: 100px;
          text-align: center;
        }
      }
      .data_trend {
        #chart_wrapper {
          height: 400px;
          width: 100%;
          background-color: salmon;
        }
      }
      .data_report {
        .module_header {
          display: flex;
          justify-content: space-between;
          line-height: 40px;
        }
      }
    }
  }
}
</style>
