<template>
  <a-modal v-model:visible="visible" :confirm-loading="confirmLoading" :mask="true" :title="modalTitle" :width="601" @ok="handleOk" :after-close="afterClose">
    <div class="mult_operation_wrapper w100 h100 flow_auto">
      <a-form class="mult_operation" :label-col="{ span: 5 }" :wrapper-col="{ span: 19 }" :colon="false" label-align="left">
        <!-- 批量绑定产品选择框-->
        <section v-if="mode == 'industrymode'" v-hasPermission="'MEDIA_BINDINDUSTRY'">
          <a-row type="flex" align="middle" v-if="formData.type != '1'">
            <a-col :span="24">
              <a-form-item label="绑定模式">
                <a-radio-group v-model:value="formData.type" button-style="solid">
                  <a-radio v-hasPermission="'MEDIA_UPDATEBINDBUSIMODE'" :value="2"> 换绑业务模式 <QuestionCircleOutlined :title="tip2" /></a-radio>
                  <a-radio v-hasPermission="'MEDIA_UPDATECURRENTBUSIMODE'" :value="3"> 修改当前 </a-radio>
                </a-radio-group>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row type="flex" align="middle" :span="19">
            <a-col :span="24">
              <a-form-item label="业务模式">
                <!-- <a-select style="width: 180px" placeholder="请选择业务模式" @change="businessModelChange" v-model:value="formData.industryModel" :options="operationModeList"></a-select> -->
                <a-radio-group v-model:value="formData.industryModel" @change="businessModelChange" button-style="solid">
                  <a-radio-button  v-for="option in operationModeList" :key="option.value" :value="option.value"> {{ option.label }}</a-radio-button>
                </a-radio-group>
              </a-form-item>
            </a-col>
          </a-row>
        </section>

        <!-- 批量绑定产品选择框-->
        <section v-if="mode == 'product'">
          <a-row type="flex" align="middle" v-if="formData.type != '1'">
            <a-col :span="24">
              <a-form-item label="绑定模式">
                <a-radio-group v-model:value="formData.type" button-style="solid">
                  <a-radio v-hasPermission="'MEDIA_UPDATEBINDPRODUCTS'" :value="2"> 换绑产品 <QuestionCircleOutlined :title="tip2" /></a-radio>
                  <a-radio v-hasPermission="'MEDIA_UPDATECURRENTPRODUCTS'" :value="3"> 修改当前 </a-radio>
                </a-radio-group>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row type="flex" align="middle">
            <a-col :span="24">
              <a-form-item label="所属产品">
                <a-select v-model:value="formData.projectCode" style="width: 200px" placeholder="请选择产品" v-bind="$filterOptions">
                  <a-select-option :disabled="accountDataProjects.includes(product.projectCode)" :label="product.projectName" @click="formData.projectName = product.projectName" :value="product.projectCode" v-for="product in productList" :key="product.id">{{ product.projectName }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>
        </section>

        <!-- 批量绑定运营选择框 -->
        <section v-if="mode == 'operation'">
          <a-row type="flex" align="middle" v-if="formData.type != '1'">
            <a-col :span="24">
              <a-form-item label="绑定模式" :colon="false" label-align="left">
                <a-radio-group v-model:value="formData.type" button-style="solid">
                  <a-radio v-hasPermission="'MEDIA_UPDATEBINDOPERATION'" :value="2"> 换绑运营/服务商 <QuestionCircleOutlined :title="tip2" /></a-radio>
                  <a-radio v-hasPermission="'MEDIA_UPDATECURRENTOPERATION'" :value="3"> 修改当前 </a-radio>
                </a-radio-group>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row type="flex" align="middle">
            <a-col :span="24">
              <a-form-item :label="columnMap(operationModeList, industryModel, 'value', 'title')" :colon="false" label-align="left">
                <a-space class="w100">
                  <a-select style="width: 180px" v-if="industryModel === 'Service'" placeholder="请选择服务商" v-model:value="formData.facilitatorId" v-bind="$filterOptions">
                    <a-select-option @click="formData.agentName = person.facilitatorName" :value="person.facilitatorId" :label="person.facilitatorName" v-for="person in agentList" :key="person.id">{{ person.facilitatorName }}</a-select-option>
                  </a-select>
                  <!-- 选择了运营模式才能选择运营，并且客户自运营不显示运营 -->
                  <a-select style="width: 180px" v-else-if="industryModel === 'Substitute'" placeholder="请选择运营" v-model:value="formData.operatorId" v-bind="$filterOptions">
                    <a-select-option @click="formData.operatorName = person.username" :value="person.userId" v-for="person in personList" :key="person.userId" :label="person.username">{{ person.username }}</a-select-option>
                  </a-select>
                  <!-- 二代 -->
                  <a-select style="width: 180px" v-else-if="industryModel === 'Self'" placeholder="请选择二代" v-model:value="formData.agentId" v-bind="$filterOptions">
                    <a-select-option @click="formData.operatorName = person.agentName" :value="person.id" v-for="person in agentDataList" :key="person.id" :label="person.agentName">{{ person.agentName }}</a-select-option>
                  </a-select>
                </a-space>
              </a-form-item>
            </a-col>
          </a-row>
        </section>

        <!-- 批量清理素材-->
        <section v-if=" mode == 'clearMaterialMaterialStatus' || mode == 'clearMaterialVideoPause' ">
          <a-col :span="22" >
            <span style="color: rgba(217,59,31,0.89);font-size: 14px" >***请注意***：必须取得客户同意后才能进行无效素材清理！</span>
            <br>
            <span style="color: rgba(217,59,31,0.89);font-size: 14px" >***请注意***：必须取得客户同意后才能进行无效素材清理！</span>
            <br>
            <span style="color: rgb(0,138,0);" >***提示信息***：系统默认只会暂停七天以前创建的素材！</span>
          </a-col>
          <a-row type="flex" align="middle">
            <a-col :span="22">
              <a-form-item label="清理模式 ：">
                <a-radio-group v-model:value="formData.clearModel" button-style="solid">
                  <a-radio :value="1" default-checked="true"  > 指定客户所有账户 </a-radio>
                  <a-radio :disabled="selectType === 'customer' " :value="0"> 指定账号 </a-radio>
                </a-radio-group>
              </a-form-item>
            </a-col>
          </a-row>

          <a-row type="flex" align="middle">
            <a-col :span="22">
              <a-form-item label="清理月份" style="width: 100%">
                <a-month-picker  style="width: 100%"  required="true"  v-model:value="formData.period"  :format="'YYYY-MM'" placeholder="默认清理当前月"/>
              </a-form-item>
            </a-col>
          </a-row>

          <a-row type="flex" align="middle">
            <a-col :span="22" >
              <a-form-item label="素材标签 ："   >
                <a-select mode="multiple"  v-model:value="formData.clearMaterialTypes"  placeholder="默认清理所有" allow-clear >
                  <a-select-option  :value="item" :label="item" v-for="item in clearMaterialTypes" :key="item">{{ item }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>

          <a-row type="flex" align="middle">
            <a-col :span="22" >
              <a-form-item label="投放状态 ："  >
                <a-select  v-model:value="formData.materialIsEffective"  placeholder="默认清理所有" allow-clear>
                  <a-select-option value="Y" >在投</a-select-option>
                  <a-select-option value="N" >未投</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>

          <a-row  type="flex" align="middle" >
            <a-col :span="22" >
              <div style="margin-left: 20px;margin-bottom:20px;width:100% ">
                <a-tooltip v-if="formData.clearModel == 0" style="width: 97%">
                  {{this.accountList.map(item => item.advertiserId).length == 0 ?'未选中任何数据':this.accountList.map(item => item.advertiserId)}}
                </a-tooltip>
                <a-tooltip v-if="formData.clearModel == 1 ">
                  {{ [...new Set(this.accountList.map(item => item.customerName))].length == 0 ? '未选中任何数据' : [...new Set(this.accountList.map(item => item.customerName))]}}
                </a-tooltip>
              </div>
            </a-col>
          </a-row>

        </section>

        <!-- 批量清理素材-->
        <section v-if="mode == 'clearMaterial'">
          <a-row type="flex" align="middle">
            <a-col :span="22">
              <a-form-item label="清理素材类型">
                <SelectMultiple v-model:value="formData.clearMaterialTypes" :select-data="formData.clearMaterialTypes" class="select-tagmode" :show-rectangle="false" :select-options="clearTypes" :label-in-value="true" placeholder="清理所有" v-bind="$multipleFilterOptions" select-option-value="value" select-option-label="label"  />
              </a-form-item>
            </a-col>
          </a-row>
          <a-row align="middle">
            <span style="color: rgba(217,59,31,0.89)" >***请注意***：必须取得客户同意后才能进行低效素材清理!!!</span>
          </a-row>
        </section>

        <section v-if="mode == 'clearMaterial' || mode == 'clearMaterialTask' ">
          <a-row type="flex" align="middle">
            <a-col :span="22">
              <a-form-item label="清理素材类型">

                <SelectMultiple v-model:value="formData.clearMaterialTypes" :select-data="formData.clearMaterialTypes" class="select-tagmode" :show-rectangle="false" :select-options="clearTypes" :label-in-value="true" placeholder="清理素材类型" v-bind="$multipleFilterOptions" select-option-value="value" select-option-label="label"  />

              </a-form-item>
            </a-col>
          </a-row>
          <a-row type="flex" align="middle">
            <span style="color: rgba(217,59,31,0.89)" >***请注意***：必须取得客户同意后才能进行低效素材清理！</span>
          </a-row>
        </section>

        <section v-if="mode == 'syncAccount'" :disabled="tableLoading">
          <a-row type="flex" align="middle">
            <a-col :span="24">
              <a-form-item label="同步类型">
                <a-radio-group v-model:value="formData.type" button-style="solid">
                  <a-radio :value="2"> 指定父账号 </a-radio>
                  <a-radio v-hasPermission="'SYNC_ALL_ACCOUNT'" :value="10"> 所有账号 </a-radio>
                </a-radio-group>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row type="flex" align="middle" v-if="formData.type == '2'">
            <a-col :span="24">
              <a-form-item label="父账号">
                <a-select style="width: 320px"  placeholder="请选择同步父账号" v-model:value="formData.advertiserId" v-bind="$filterOptions">
                  <a-select-option @click="formData.advertiserId = accManager.advertiserId" :value="accManager.advertiserId" :label="accManager.advertiserId+'-'+accManager.advertiserName " v-for="accManager in managerAccountListTemp" :key="accManager.advertiserId">{{ accManager.advertiserId }}-{{accManager.advertiserName}}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>
        </section>

        <section v-if="mode == 'verifyAccountData'" :disabled="tableLoading">
          <a-row type="flex" align="middle">
            <a-col :span="24">
              <a-form-item label="校准类型">
                <a-radio-group v-model:value="formData.verifyAccountDataType" button-style="solid">
                  <a-radio :value="0"> 仅账号 </a-radio>
                  <a-radio :value="1"> 账号及素材 </a-radio>
                </a-radio-group>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row type="flex" align="middle">
            <a-col :span="24">
              <a-form-item label="日期范围" >
                <a-range-picker :placeholder="['开始日期', '结束日期']" v-model:value="defaultRange" separator="到" :format="[dateRangeFormat, dateRangeFormat]" :ranges="dateRanges" :disabled-date="disabledDateRange" @change="onDateRangeChange" :disabled="tableLoading" />
              </a-form-item>
            </a-col>
          </a-row>
        </section>

        <!-- 批量绑定业务部门 -->
        <section v-if="mode == 'businessDeptBind' " >
          <a-row type="flex" align="middle">
            <a-col :span="22">
              <a-form-item label="批量绑定业务部门" >
                  <a-select style="margin-left:20px;width: 180px" placeholder="请选择业务部门" v-model:value="formData.businessDept" v-bind="$filterOptions">
                    <a-select-option v-for="dept in businessDeptList" :key="dept.name" :value="dept.value" :label="dept.name">{{ dept.name }}</a-select-option>
                  </a-select>
              </a-form-item>
            </a-col>
          </a-row>
        </section>

<!--        资金置换-->
        <section v-if=" mode == 'zjzh' ">
          <span style="color: rgba(217,59,31,0.89);font-size: 14px" >************您正在使用【资金置换】操作，请注意核实操作信息！！！************</span>
          <br/>
          <span style="color: rgba(217,59,31,0.89);font-size: 14px" >************您正在使用【资金置换】操作，请注意核实操作信息！！！************</span>
          <br/>
          <span style="color: rgba(217,59,31,0.89);font-size: 14px" >************您正在使用【资金置换】操作，请注意核实操作信息！！！************</span>
          <br/>


          <a-row  type="flex" align="middle" >
            <span style="font-size: 14px">【最大置换金额】：</span>
            <a-col :span="22" >
              <div style="margin-left: 20px;margin-bottom:20px;width:100% ">
                <a-input-number required="true" style="width: 100%" :min="0" v-model:value="formData.maxAmount" placeholder="请输入【最大置换金额】" />
              </div>
            </a-col>
          </a-row>

          <span style="font-size: 14px">账号列表：</span>
          <a-row  type="flex" align="middle" >
            <a-col :span="22" >
              <div style="margin-left: 20px;margin-bottom:20px;width:100% ">
                <a-tooltip  style="width: 97%">
                  {{this.accountList.map(item => item.advertiserId).length == 0 ?'未选中任何数据':this.accountList.map(item => item.advertiserId)}}
                </a-tooltip>
              </div>
            </a-col>
          </a-row>

        </section>


        <a-row type="flex" align="middle" v-if="formData.type != '1' && formData.type != '2' && formData.type != '10' && formData.type != '3' && formData.type != '4' ">
          <a-col :span="5"><span> 关系起始时间</span> </a-col>
          <a-col :span="10">
            <a-date-picker style="" v-model:value="formData.startTime" value-format="YYYY-MM-DD" class="blue-icon" />
          </a-col>
        </a-row>
        <a-row v-show="mode === 'operation' && formData.industryModel === 'Level'">
          <a-col :span="24">
            <a style="color: #ddd" @click="addAgent">>> 无服务商信息，添加</a>
          </a-col>
        </a-row>
      </a-form>
    </div>
  </a-modal>
  <component @after-close="getServiceListData, getSelfListData" :is="componentName" ref="addAgentModal" v-if="componentName"></component>
</template>

<script>
import { QuestionCircleOutlined } from '@ant-design/icons-vue';
import { assignBusiness, assignProducts, assignOperations,materialClearTaskCreate ,getManagerAccountListData,refreshAccount,
  clearMaterialMaterialStatus,clearMaterialVideoPause,businessDeptBindApi,agentAdvertiserRechargeFundExchangeApi
} from '@/api/mediaAccount/mediaAccount.js';
import AddAgentModal from './AddAgentModal.vue';
import moment from 'moment';
import SelectMultiple from '@/components/selectMultiple/index.vue';

import { columnMap } from '@/utils/utils.js';
import {ref} from "vue";

export default {
  name: 'AssignManyAccount',
  components: { QuestionCircleOutlined, AddAgentModal, SelectMultiple, },
  emits: ['afterSave'],
  props: {
    selectType:{ type: String, default: 'customer' },
    clearMaterialTypes: { type: Array, default: () => [] },
    agentId: { type: Number, default: null },
    customerName: { type: String, default: '' },
    // customerNames: { type: Array, default: () => [] }, //选中账户的所有客户
    activeTabKey: { type: String, default: '' }, //当前选中的tab
    accountList: { type: Array, default: () => [] }, //需要批量绑定的数组
    personList: { type: Array, default: () => [] }, //运营人员列表
    productList: { type: Array, default: () => [] }, //产品人员列表
    managerAccountList: { type: Array, default: () => [] },
  },
  inject: ['getAgentList', 'getFacilitatorList','mdaccountAccManagerList'],
  data() {
    const dateRangeFormat = 'YYYY-MM-DD';
    const dateRanges = {
      昨天: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
      最近三天: [moment().subtract(2, 'days'), moment()],
      本周: [moment().startOf('week'), moment.min(moment(), moment().endOf('week'))],
      最近七天: [moment().subtract(6, 'days'), moment()],
      本月: [moment().startOf('month'), moment.min(moment(), moment().endOf('month'))],
      最近三十天: [moment().subtract(29, 'days'), moment()],
      最近九十天: [moment().subtract(89, 'days'), moment()],
    };
    //禁止rangepick选择今天开始的日期
    const disabledDateRange = current => {
      return current && current > moment().add(1, 'days').startOf('day');
    };
    // rangepicker默认选中日期
    const defaultRange = ref([moment(), moment()]);
    const operationModeList = [
      { value: 'Self', label: '二代', title: '所属二代' },
      { value: 'Substitute', label: '代运营', title: '所属运营' },
      { value: 'Service', label: '服务商', title: '所属服务商' },
      { value: 'CusSelfOpera', label: '客户自运营' },
    ];
    const clearTypes = [
      { value: 'INEFFICIENT_MATERIAL', label: '低效素材', title: '低效素材' },
      { value: 'SIMILAR_MATERIAL', label: '同质化挤压严重素材', title: '同质化挤压严重素材' },
      { value: 'SIMILAR_QUEUE_MATERIAL', label: '同质化排队素材', title: '同质化排队素材' },
    ];
    const clearModels = [
      { value: 'ADVERTISER', selected: true,label: '指定选中账号', title: '指定选中账号' },
      { value: 'CUSTOMER', label: '筛选客户所有账号', title: '筛选客户所有账号' },
    ];
    const mediaChannel = 'TT' ;
    const empty = { facilitatorId: undefined, agentName: undefined, industryModel: undefined,
      mdaccountIdList: [], operatorId: undefined, operatorName: undefined, projectCode: undefined,
      projectName: undefined, endTime: moment(), startTime: moment('2021-07-01').format('YYYY-MM-DD'),
      clearMaterialTypes: [],clearModel:1,advertiserId:undefined,type: 1,businessDept:undefined,maxAmount:undefined};
    return {
      size: ref('small'),
      businessDeptList:[
        {name:'业务一部',value:'业务一部'},
        {name:'业务二部',value:'业务二部'},
        {name:'业务三部',value:'业务三部'},
        {name:'业务四部',value:'业务四部'},
        {name:'业务五部',value:'业务五部'},
        {name:'业务六部',value:'业务六部'},
        {name:'圣达际',value:'圣达际'},
        {name:'洽尔',value:'洽尔'},
        {name:'MCN',value:'MCN'},
        {name:'其他',value:'其他'},
      ],
      columnMap,
      confirmLoading:false,
      visible: false,
      radioValue: 1,
      operationModeList, //业务模式
      agentList: [], //服务商列表
      agentDataList: [], //二级代理列表
      dateRange: [], //时间范围
      componentName: '',
      empty,
      formData: { ...empty },
      mode: '',
      tip2: '保留历史记录',
      userInfoJobs: this.$store.state.oauth.userInfo.jobs,
      clearTypes,
      clearModels,
      mediaChannel,
      managerAccountListTemp:[],
      tableLoading: false,
      dateRanges,
      defaultRange,
      dateRangeFormat,
      disabledDateRange,
    };
  },
  computed: {
    clearModelDefaultValue(){
      return (this.selectType === 'customer')?1:0;
    },
    modalTitle() {
      return { industrymode: '批量业务模式', product: '批量绑定产品', operation: '批量绑定运营',clearMaterial: '批量清理素材',clearMaterialTask: '批量清理素材-正在投放中的素材',clearMaterialMaterialStatus: '批量暂停广告素材',clearMaterialVideoPause: '批量暂停素材-不会暂停自动化工具下的素材',syncAccount: '同步账号',verifyAccountData: '数据校准' }[this.mode];
      return { industrymode: '批量业务模式', product: '批量绑定产品', operation: '批量绑定运营',clearMaterial: '批量清理素材',syncAccount: '同步账号',verifyAccountData: '数据校准' ,businessDeptBind: '批量绑定业务部门'}[this.mode];
    },
    accountDataProjects() {
      return this.accountList.map(item => item.projectCode);
    },
    industryModel() {
      return this.accountList.length > 0 ? this.accountList[0].industryModel : '';
    },
    clearDataProjects() {
      return this.formData.clearMaterialTypes.map(item => item.value);
    },
  },
  watch: {
    visible(val) {
      if (val) {
        this.getServiceListData();
        this.getSelfListData();
        let firstRow = this.accountList[0];
        if ((this.mode == 'industrymode' && firstRow.industryModel === null) || (this.mode == 'product' && firstRow.projectCode === null) || (this.mode == 'operation' && ((firstRow.industryModel == 'Substitute' && firstRow.operaterId === null) || (firstRow.industryModel == 'Service' && firstRow.facilitatorId === null)))) {
          this.formData.type = 1;
        }else if(this.mode == 'clearMaterial' || this.mode == 'syncAccount' || this.mode == 'clearMaterialTask' || this.mode == 'clearMaterialMaterialStatus' || this.mode == 'clearMaterialVideoPause'){
          this.formData.type = 2;
          this.getManagerAccountListTemp();
        }else if(this.mode == "businessDeptBind"){
          this.formData.type = 3;
        }else if(this.mode == "zjzh"){//资金置换
          this.formData.type = 4;
        }else {
          this.formData.type = '';
        }
      }
    },
  },
  methods: {
    // 开始时间结束时间选择
    onDateRangeChange(val, dateString) {
      this.filterConditions.startTime = dateString[0];
      this.filterConditions.endTime = dateString[1];
      this.tablePagination.page = '1';
      this.getAdvertiserReport();
    },
    // 运营仅能选择近五天时间
    disabledDate(current) {
      if (this.userInfoJobs == 'OPERATION') {
        return current > moment() || current < moment().subtract(5, 'days');
      } else {
        return false;
      }
    },
    getManagerAccountListTemp(){
      getManagerAccountListData(this.mediaChannel).then(res => {
        if (res.code == 0) {
          this.managerAccountListTemp  = res.data;
        }
      });
    },
    // 是否有绑定记录
    hasBindHistory() {
      let index = undefined;
      if (this.mode === 'product') {
        index = this.accountList.findIndex(item => item.projectCode);
      } else if (this.mode === 'operation') {
        index = this.accountList.findIndex(item => item.operatorId);
      } else {
        index = -1;
      }
      if (index > -1) {
        this.$message.warning('已存在绑定记录');
      }
      return index > -1;
    },
    // 点击保存
    async handleOk() {
      // this.visible = false;
      this.confirmLoading = true;
      this.tableLoading = true;
      if(this.mode === 'syncAccount'){
        if(this.formData.type===10) {
          this.formData.advertiserId=null;
        }else {
          if(!this.formData.advertiserId){
            this.$message.warning('请选择需要刷新的账号！');
            this.confirmLoading = false;
            return;
          }
        }
        let res ;
        if(this.mediaChannel === 'TT' || this.mediaChannel === 'GDT' || this.mediaChannel === 'QC'){
          res = await refreshAccount(this.mediaChannel,this.formData.advertiserId);
        }else {
          this.$emit('afterSave');
          this.$message.success('媒体渠道未开通！');
          this.visible = false;
          this.confirmLoading = false;
          return ;
        }
        this.$message.success('完成账号同步！');
        this.visible = false;
        // if (res.code === 0) {
        //   this.$emit('afterSave');
        //   this.$message.success('完成账号同步！');
        //   this.visible = false;
        // }
        this.tableLoading = false;
        this.confirmLoading = false;
        return ;
      }

      // 有绑定记录不能新绑
      if (this.formData.type == 1 && this.hasBindHistory()) {
        this.confirmLoading = false;
        return;
      }
      const data = { ...this.formData };
      let mdaccountIdList = this.accountList.map(item => item.advertiserId);
      // 绑定产品数据
      const projects = { endTime: data.endTime, projectCode: data.projectCode, projectName: data.projectName, startTime: data.startTime };
      // 如果新增，startTime等于当天
      if (data.type == 1) {
        projects.startTime = moment('2021-07-01').format('YYYY-MM-DD');
      }
      // 绑定运营数据
      const operaters = { ...data };
      delete operaters.mdaccountIdList;
      delete operaters.projectName;
      delete operaters.projectCode;

      let res = {};
      if (this.mode === 'product') {
        if (!data.projectCode) {
          this.$message.warning('请选择产品！');
          this.confirmLoading = false;
          return;
        }
        if (!data.startTime && !data.type === 2) {
          this.$message.warning('请选择时间！');
          this.confirmLoading = false;
          return;
        }
        // 请求数据
        const obj = { projects: [projects], mdaccountIdList: mdaccountIdList, type: data.type };
        res = await assignProducts(obj);
      } else if (this.mode === 'operation') {
        if (data.facilitatorId || data.operatorId || data.agentId) {
          // 请求数据
          const obj = { operaters: [operaters], mdaccountIdList: mdaccountIdList, type: data.type };
          res = await assignOperations(obj);
        } else {
          this.$message.warning('运营人员不能为空！');
        }
      }else if(this.mode === 'clearMaterial'){
        let clearMaterialTypes = this.formData.clearMaterialTypes.map(item => item.value);
        const obj = { clearMaterialTypes: clearMaterialTypes, advertiseIds: mdaccountIdList, mediaChannel: "TT", materialSource:"AD"};
        res = await materialClearTaskCreate(obj);
      }else if(this.mode === 'clearMaterialTask'){
        let clearMaterialTypes = this.formData.clearMaterialTypes.map(item => item.value);
        const obj = { clearMaterialTypes: clearMaterialTypes, advertiseIds: mdaccountIdList, mediaChannel: "TT", materialSource:"AD"};
        res = await materialClearTaskCreate(obj);
      }else if(this.mode === 'clearMaterialMaterialStatus'){
        let customerNames =  [...new Set(this.accountList.map(item => item.customerName))];
        let periodStr = moment(this.formData.period).format('YYYY-MM');
        const obj = { clearMaterialTypes: this.formData.clearMaterialTypes, advertiseIds: mdaccountIdList, mediaChannel: "TT",agentId:this.agentId
          ,customerNames:customerNames,clearModel:this.formData.clearModel,period:periodStr,materialIsEffective:this.formData.materialIsEffective};
        res = await clearMaterialMaterialStatus(obj);
      }else if(this.mode === 'clearMaterialVideoPause'){
        let customerNames =  [...new Set(this.accountList.map(item => item.customerName))];
        let periodStr = moment(this.formData.period).format('YYYY-MM');
        const obj = { clearMaterialTypes: this.formData.clearMaterialTypes, advertiseIds: mdaccountIdList, mediaChannel: "TT",agentId:this.agentId
          ,customerNames:customerNames,clearModel:this.formData.clearModel,period:periodStr,materialIsEffective:this.formData.materialIsEffective};
        res = await clearMaterialVideoPause(obj);
      }else if(this.mode === 'businessDeptBind'){
        const obj = {advertiseIds: mdaccountIdList, businessDept: this.formData.businessDept,mediaChannel: this.activeTabKey};
        res = await businessDeptBindApi(obj);
      }else if(this.mode === 'zjzh'){//资金置换
        const obj = {advertiserIds: mdaccountIdList, userCode: this.$store.state.oauth.userInfo.userCode,maxAmount:this.formData.maxAmount};
        res = await agentAdvertiserRechargeFundExchangeApi(obj);
      }else {
        if (!data.industryModel) {
          this.$message.warning('请选择业务模式！');
        } else {
          // 请求数据
          const obj = { industryModel: data.industryModel, startTime: data.startTime, mdaccountIdList: mdaccountIdList, type: data.type };
          res = await assignBusiness(obj);
        }
      }
      if (res.code === 0) {
        this.$emit('afterSave');
        if(this.mode === 'clearMaterial' || this.mode === 'clearMaterialTask'){
          this.$message.success('已提交素材清理任务！');
        }else if(this.mode === 'clearMaterialMaterialStatus' || this.mode === 'clearMaterialVideoPause'){
          this.$message.success('素材暂停中！');
        }else if(this.mode === 'zjzh'){
          this.$message.success('资金置换完成！');
        }else{
          this.$message.success('已保存！');
        }
        this.visible = false;
        this.confirmLoading = false;
      }
    },
    // 获取服务商列表
    async getServiceListData() {
      this.agentList = this.getFacilitatorList();
    },
    // 获取二代列表
    async getSelfListData() {
      this.agentDataList = this.getAgentList();
    },
    // 打开新增服务商弹框
    addAgent() {
      this.componentName = 'add-agent-modal';
      this.$nextTick(() => {
        this.$refs.addAgentModal.visible = true;
      });
    },
    // 关闭批量绑定弹框
    afterClose() {
      this.formData = { ...this.empty };
      this.dateRange = [];
    },
    // 业务模式改变时
    businessModelChange() {
      let val = this.formData.industryModel;
      if (val === 'Self') {
        this.formData.operatorName = '二代';
        this.formData.operatorId = 113;
        this.formData.agentName = undefined;
        this.formData.facilitatorId = undefined;
      } else {
        this.formData.agentName = undefined;
        this.formData.facilitatorId = undefined;
        this.formData.operatorName = undefined;
        this.formData.operatorId = undefined;
      }
    },
  },
};
</script>

<style  lang="less">
.mult_operation {
  overflow: auto;

  .ant-form-item-label {
    line-height: 40px;
  }
  .anticon-question-circle svg {
    font-size: 14px;
  }
  .ant-calendar-picker-input {
    height: 32px !important;
  }
  .ant-radio-button-wrapper {
    width: 96px;
    text-align: center;
  }
}

</style>
